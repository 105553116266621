import { Box, Icon } from '@chakra-ui/react'

import CO2 from '../../icons/co2'
import Heating from '../../icons/heating'
import TotalGas from '../../icons/totalGas'
import TotalElectricity from '../../icons/totalElectricity'
import Renewables from '../../icons/renewables'
import WaterUsage from '../../icons/waterUsage'
import Lighting from '../../icons/lighting'
import WholeBuilding from '../../icons/wholeBuilding'
import Cooling from '../../icons/cooling'
import HotWater from '../../icons/hotWater'
import ProcessPower from '../../icons/processPower'
import SmallPower from '../../icons/smallPower'
import Ventilation from '../../icons/ventilation'
import Coffee from '../../icons/coffee'
import Lab from '../../icons/lab'
import Office from '../../icons/office'
import Education from '../../icons/education'
import Question from '../../icons/question'
import Info from '../../icons/info'
import Speedo from '../../icons/speedo'

export type CardIconType = {
  width: number | string | undefined
  height?: number | string | undefined
  color?: string
  iconType: string
}

type IconsType = {
  [key: string]: any
}
const icons: IconsType = {
  CarbonDioxide: CO2,
  Cooling: Cooling,
  Heating: Heating,
  HotWater: HotWater,
  Lighting: Lighting,
  ProcessPower: ProcessPower,
  Renewables: Renewables,
  SmallPower: SmallPower,
  TotalElectricity: TotalElectricity,
  TotalGas: TotalGas,
  Ventilation: Ventilation,
  WaterUsage: WaterUsage,
  WholeBuilding: WholeBuilding,
  Cafe: Coffee,
  Lab: Lab,
  Office: Office,
  Education: Education,
  Question: Question,
  Info: Info,
  Speedo: Speedo,
}

const CardIcons = ({ iconType, color, width, height }: CardIconType) => {
  return (
    <Box>
      <Icon
        as={icons[iconType]}
        width={width}
        height={height || width}
        color={color}
      />
    </Box>
  )
}

export default CardIcons
