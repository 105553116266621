import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type PfNormRadioProps = {
  value: string
  onChange: (value: string) => void
}

const PfNormRadio = ({ value, onChange }: PfNormRadioProps) => {
  const { t } = useTranslation(['portfolio'])
  return (
    <RadioGroup
      defaultValue="total"
      size="sm"
      onChange={(value) => onChange(value)}
      value={value}
    >
      <Stack spacing={2} direction="row">
        <Radio value="total" spacing={1}>
          <Text fontSize="xs">{t('total')}</Text>
        </Radio>
        <Radio value="sqm" spacing={1}>
          <Text fontSize="xs">
            {t('per')} m<sup>2</sup>
          </Text>
        </Radio>
        <Radio value="sqft" spacing={1}>
          <Text fontSize="xs">
            {t('per')} ft<sup>2</sup>
          </Text>
        </Radio>
      </Stack>
    </RadioGroup>
  )
}

export default PfNormRadio
