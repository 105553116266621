import {
  Box,
  Center,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface KeyFactsProps {
  loading: boolean
  leadEngName?: string
  clientName?: string
  clientLeadName?: string
}

const KeyFacts = ({
  loading,
  leadEngName,
  clientName,
  clientLeadName,
}: KeyFactsProps) => {
  const { t, i18n } = useTranslation('building')
  return (
    <Box bgColor={'purple.700'} textColor={'gray.300'} h="100%">
      <SimpleGrid columns={3}>
        <GridItem colSpan={1} display={{ base: 'none', md: 'flex' }}></GridItem>
        <GridItem colSpan={{ base: 3, md: 2 }}>
          <Center>
            <Box w="70%">
              <Stack direction="column" spacing={1} my={10}>
                <Heading as="h3" size="md">
                  {t('keyFacts').toLocaleUpperCase(i18n.resolvedLanguage)}
                </Heading>
                <Flex justifyContent={'space-between'}>
                  <Text>{t('leadEng')}</Text>
                  <Skeleton isLoaded={!loading}>
                    <Text>{leadEngName ?? 'Eddie Van Halen'}</Text>
                  </Skeleton>
                </Flex>
                <Flex justifyContent={'space-between'}>
                  <Text>{t('client')}</Text>
                  <Skeleton isLoaded={!loading}>
                    <Text>{clientName ?? 'Opti-Twin Ltd'}</Text>
                  </Skeleton>
                </Flex>
                <Flex justifyContent={'space-between'}>
                  <Text>{t('clientLead')}</Text>
                  <Skeleton isLoaded={!loading}>
                    <Text>{clientLeadName ?? 'Some Company PLC'}</Text>
                  </Skeleton>
                </Flex>
              </Stack>
            </Box>
          </Center>
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

export default KeyFacts
