import { createIcon } from '@chakra-ui/react'

const WholeBuilidng = createIcon({
  displayName: 'WholeBuildingEnergy',
  viewBox: '0 0 227 220',
  path: (
    <g id="g1730" transform="translate(-50.495,-67)">
      <g id="g1728">
        <polygon
          fill="currentColor"
          points="62.134,287 50.495,287 50.495,145.602 97.627,145.602 97.627,157.242 62.134,157.242 "
          id="polygon1704"
        />
        <polygon
          fill="currentColor"
          points="276.731,287 265.092,287 265.092,157.242 229.599,157.242 229.599,145.602 276.731,145.602 "
          id="polygon1706"
        />
        <rect
          fill="currentColor"
          x="79.893997"
          y="186.755"
          width="15.683"
          height="15.683"
          id="rect1708"
        />
        <rect
          fill="currentColor"
          x="79.893997"
          y="222.599"
          width="15.683"
          height="15.683"
          id="rect1710"
        />
        <rect
          fill="currentColor"
          x="79.893997"
          y="258.444"
          width="15.683"
          height="15.683"
          id="rect1712"
        />
        <rect
          fill="currentColor"
          x="231.18401"
          y="184.89301"
          width="15.683"
          height="15.683"
          id="rect1714"
        />
        <rect
          fill="currentColor"
          x="231.18401"
          y="222.599"
          width="15.683"
          height="15.683"
          id="rect1716"
        />
        <rect
          fill="currentColor"
          x="231.18401"
          y="258.444"
          width="15.683"
          height="15.683"
          id="rect1718"
        />
        <polygon
          fill="currentColor"
          points="208.533,121.309 163.613,83.932 118.693,121.309 118.693,287 107.054,287 107.054,112.25 163.613,67 220.172,112.25 220.172,287 208.533,287 "
          id="polygon1720"
        />
        <rect
          fill="currentColor"
          x="139.815"
          y="164.455"
          width="10.455"
          height="122.545"
          id="rect1722"
        />
        <rect
          fill="currentColor"
          x="176.95599"
          y="164.455"
          width="10.455"
          height="122.545"
          id="rect1724"
        />
        <rect
          fill="currentColor"
          x="155.771"
          y="128.334"
          width="15.683"
          height="15.683"
          id="rect1726"
        />
      </g>
    </g>
  ),
})

export default WholeBuilidng
