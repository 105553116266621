import { useReducer } from 'react'
import { BuildingProps } from './pfTable'

type ReducerState = {
  siteName: string
  energy: string
  carbon: string
  renewables: string
  sortType: string
  sortKey: string
}

type ReducerAction = {
  type: string
  payload: string
}

const initialState: ReducerState = {
  siteName: 'none',
  energy: 'none',
  carbon: 'none',
  renewables: 'none',
  sortType: 'none',
  sortKey: 'none',
}

const reducer = (_state: ReducerState, action: ReducerAction): ReducerState => {
  const { type, payload } = action
  switch (type) {
    case 'asc':
      return {
        siteName: payload === 'siteName' ? 'asc' : 'none',
        energy: payload === 'energy' ? 'asc' : 'none',
        carbon: payload === 'carbon' ? 'asc' : 'none',
        renewables: payload === 'renewables' ? 'asc' : 'none',
        sortType: 'asc',
        sortKey: payload,
      }
    case 'dsc':
      return {
        siteName: payload === 'siteName' ? 'dsc' : 'none',
        energy: payload === 'energy' ? 'dsc' : 'none',
        carbon: payload === 'carbon' ? 'dsc' : 'none',
        renewables: payload === 'renewables' ? 'dsc' : 'none',
        sortType: 'dsc',
        sortKey: payload,
      }
    case 'none':
    default:
      return {
        siteName: 'none',
        energy: 'none',
        carbon: 'none',
        renewables: 'none',
        sortType: 'none',
        sortKey: 'none',
      }
  }
}

const useMultiSort = () => {
  const [state, dispatch] = useReducer(reducer, initialState),
    { sortKey, sortType } = state

  const sortFunction = (a: BuildingProps, b: BuildingProps): number => {
    const isInfinity = (element: { value: number }): boolean =>
      element.value === Infinity
    const infinityPresentA = a.tlData.some(isInfinity) ? 1 : 0
    const infinityPresentB = b.tlData.some(isInfinity) ? 1 : 0
    if (sortType === 'none') {
      return infinityPresentA - infinityPresentB || a.name.localeCompare(b.name)
    }

    if (sortKey === 'siteName') {
      return sortType === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    }
    const valueA = a.tlData.find((datum) => datum.type === sortKey)?.value ?? 0
    const valueB = b.tlData.find((datum) => datum.type === sortKey)?.value ?? 0

    return (
      infinityPresentA - infinityPresentB ||
      (sortType === 'asc' ? valueA - valueB : valueB - valueA)
    )
  }

  return {
    state,
    dispatch,
    sortFunction,
  }
}

export default useMultiSort
