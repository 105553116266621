import { useRef, useEffect } from 'react'
import { motion, useSpring } from 'framer-motion'

import useRafLoop from '../../hooks/use-raf-loop'
import { Text, useMediaQuery } from '@chakra-ui/react'
import Lighting from '../../elements/icons/lighting'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'

type MarqueeProps = {
  phrase: string[]
  textSpeed: number
}

const MarqueeItem = ({ content, speed }: { content: string; speed: any }) => {
  const item = useRef<HTMLDivElement>(null)
  const rect = useRef<any>(null) // returning error with types
  const x = useRef<number>(0)

  const setX = () => {
    if (!item.current || !rect.current) return
    const xPercentage = (x.current / rect.current.width) * 100
    if (xPercentage < -100) x.current = 0
    if (xPercentage > 0) x.current = -rect.current.width
    item.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`
  }

  useEffect(() => {
    rect.current = item.current!.getBoundingClientRect()
  }, [])

  const loop = () => {
    x.current -= speed.get()
    setX()
  }
  useRafLoop(loop, true)

  const [is4k, is2k] = useMediaQuery(mediaQueries)
  return (
    <>
      <Text
        whiteSpace={'nowrap'}
        fontSize={{
          base: 25,
          md: 25,
          lg: 16,
          xl: 20,
          '2xl': is4k ? '80px' : is2k ? '40px' : 36,
        }}
        ref={item}
        color={'grey.800'}
      >
        <Lighting />
        {content}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* Need to find out a way to efficiently do this */}
      </Text>
    </>
  )
}

const Marquee = ({ phrase, textSpeed }: MarqueeProps) => {
  const marquee = useRef<HTMLDivElement>(null)
  const speed = useSpring(textSpeed, {
    damping: 40,
    stiffness: 90,
    mass: 5,
  })
  const content = phrase.join(' ')

  return (
    <>
      <motion.div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          height: '100%',
        }}
        ref={marquee}
      >
        <MarqueeItem content={content} speed={speed} />
        <MarqueeItem content={content} speed={speed} />
        <MarqueeItem content={content} speed={speed} />
      </motion.div>
    </>
  )
}

export default Marquee
