import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const colors = {
  purple: {
    50: '#F2EEFB',
    100: '#D8CDF4',
    200: '#B29AEA',
    300: '#7E57DB',
    400: '#5327B9',
    500: '#3C1D87',
    600: '#261254',
    700: '#170B33',
    800: '#0F0722',
    900: '#080411',
    gradient: 'linear-gradient(to left, #372E70 39.46%, #150E38 100%)',
    radial:
      'radial-gradient(40% 70% at 50% 50%, #5F588D 0%, #5F588D 24.65%, #5F588D 28.89%, #544C7F 44.32%, #362C5A 73.39%, #170B33 100%)',
  },
  grey: {
    100: 'rgb(232, 236, 235)',
    300: 'rgb(215, 221, 219)',
    500: 'rgb(123, 151, 152)',
    800: 'rgb(65, 126, 154)', // font
    900: 'rgb(59, 102, 118)',
  },
  success: 'rgb(123, 164, 8)',
  neutral: 'rgb(21, 147, 198)',
  danger: 'rgb(242, 37, 62)',
  warn: 'rgb(237,181,24)',
  green: {
    500: 'rgb(141, 180, 3)',
    gradient: 'linear-gradient(to right, #91B706 48.33%, #495C03 100%)',
    gradientTab:
      'linear-gradient(90deg, rgb(145, 183, 6) 100%, rgb(73, 92, 3) 42%)',
  },
  white: {
    gradient:
      'linear-gradient(90deg, rgb(179, 177, 192) 0%, rgb(255, 255, 255) 50%)',
  },
}

const fonts = {
  heading: `bouldextralight, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `bouldregular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `silkamonoregular, SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
}

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
}

const floatingInputLabel = {
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 3,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
}

const theme = extendTheme({ colors, fonts, config, ...floatingInputLabel })

export default theme
