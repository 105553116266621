import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { createContext, ReactNode, useContext, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
  AUTH_TOKEN,
  USER_AVATAR,
  USER_FIRST_NAME,
  USER_ID,
  USER_IS_ADMIN,
  USER_IS_STAFF,
  USER_LAST_NAME,
  USER_SLUG,
} from '../constants'

type AuthLib = {
  signIn: (
    email: string,
    password: string,
    callback: VoidFunction,
  ) => Promise<void>
  signOut: (callback: VoidFunction) => void
  token: string | null
  message: string
  loading: boolean
  user: {
    id: string
    firstName: string
    lastName: string
    slug: string
    avatar: string
    isStaff: boolean
    isAdmin: boolean
  }
}

const AuthContext = createContext<AuthLib>({
  signIn: async (
    _email: string,
    _password: string,
    _callback: VoidFunction,
  ): Promise<void> => {
    return
  },
  signOut: (_callback: VoidFunction) => {},
  token: null,
  message: '',
  loading: false,
  user: {
    id: '',
    firstName: '',
    lastName: '',
    slug: '',
    avatar: '',
    isStaff: false,
    isAdmin: false,
  },
})

type ProvideAuthProps = {
  children: ReactNode
}

export function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function RequireAuth({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const auth = useAuth()
  const location = useLocation()

  if (auth.token === null) {
    // Redirect to login page, but save target location so we
    // can return them the correct place after login
    return <Navigate to="/signin" state={{ from: location }} replace />
  }

  return children
}

export function useAuth() {
  return useContext(AuthContext)
}

const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      message
      user {
        id
        firstName
        lastName
        slug
        picture
        isStaff
        isAdmin
      }
    }
  }
`

const SIGN_OUT = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`

const ME_QUERY = gql`
  query meQuery($id: Int!) {
    me(id: $id) {
      token
    }
  }
`

function useProvideAuth(): AuthLib {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem(AUTH_TOKEN) || null,
  )
  const [message, setMessage] = useState('')
  const [userId, setUserId] = useState(localStorage.getItem(USER_ID) || '')
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(USER_FIRST_NAME) || '',
  )
  const [userLastName, setUserLastName] = useState(
    localStorage.getItem(USER_LAST_NAME) || '',
  )
  const [userIsAdmin, setUserIsAdmin] = useState(
    localStorage.getItem(USER_IS_ADMIN) === 'TRUE' ? true : false,
  )
  const [userIsStaff, setUserIsStaff] = useState(
    localStorage.getItem(USER_IS_STAFF) === 'TRUE' ? true : false,
  )
  const [userSlug, setUserSlug] = useState(
    localStorage.getItem(USER_SLUG) || '',
  )

  const [userAvatar, setUserAvatar] = useState(
    localStorage.getItem(USER_AVATAR) || '',
  )

  useQuery(ME_QUERY, {
    variables: { id: userId === null ? -1 : +userId },
    pollInterval: 60000 * 5,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // We've called the query, it shouldn't error, but will
      // let us know if the auth-token as expired
      if (data.me.token) {
        localStorage.setItem(AUTH_TOKEN, data.me.token)
      } else {
        signout(() => {})
      }
    },
  })

  const [signIn, { loading: signInLoading }] = useMutation(SIGN_IN)

  const [signOut, { loading: signOutLoading }] = useMutation(SIGN_OUT, {
    onCompleted({ signOut }) {
      //console.log(signOut.message)
      setMessage(signOut.message)
    },
    onError(error) {
      alert(error)
    },
  })

  const apolloClient = useApolloClient()

  const signin = async (
    email: string,
    password: string,
    callback: VoidFunction,
  ): Promise<void> => {
    try {
      await signIn({
        variables: { email, password },
        onCompleted({ signIn }) {
          // Check we signed in successfully
          if (!signIn.token) {
            return
          }

          // Sign the user in
          localStorage.setItem(AUTH_TOKEN, signIn.token)
          localStorage.setItem(USER_ID, signIn.user.id)
          localStorage.setItem(USER_FIRST_NAME, signIn.user.firstName)
          localStorage.setItem(USER_LAST_NAME, signIn.user.lastName)
          localStorage.setItem(
            USER_IS_ADMIN,
            signIn.user.isAdmin ? 'TRUE' : 'FALSE',
          )
          localStorage.setItem(
            USER_IS_STAFF,
            signIn.user.isStaff ? 'TRUE' : 'FALSE',
          )
          localStorage.setItem(USER_SLUG, signIn.user.slug)
          localStorage.setItem(USER_AVATAR, signIn.user.picture)
          setAuthToken(signIn.token)
          setMessage(signIn.message)
          setUserId(signIn.user.id)
          setUserFirstName(signIn.user.firstName)
          setUserLastName(signIn.user.lastName)
          setUserIsAdmin(signIn.user.isAdmin)
          setUserIsStaff(signIn.user.isStaff)
          setUserSlug(signIn.user.slug)
          setUserAvatar(signIn.user.picture)
          callback()
        },
        onError(error) {
          alert(error)
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const signout = async (callback: VoidFunction): Promise<void> => {
    try {
      //console.log(`Siging Out`)
      await signOut()
      await apolloClient.clearStore()
      localStorage.removeItem(AUTH_TOKEN)
      localStorage.removeItem(USER_ID)
      localStorage.removeItem(USER_FIRST_NAME)
      localStorage.removeItem(USER_LAST_NAME)
      localStorage.removeItem(USER_IS_ADMIN)
      localStorage.removeItem(USER_IS_STAFF)
      localStorage.removeItem(USER_SLUG)
      setAuthToken(null)
      setUserId('')
      setUserFirstName('')
      setUserLastName('')
      setUserIsAdmin(false)
      setUserIsStaff(false)
      setUserSlug('')
      //console.log(`Done signing out`)
    } finally {
      //console.log(`Calling back to redirect`)
      callback()
    }
  }

  const loading = signInLoading || signOutLoading

  return {
    signIn: signin,
    signOut: signout,
    token: authToken,
    message: message,
    loading,
    user: {
      id: userId,
      firstName: userFirstName,
      lastName: userLastName,
      avatar: userAvatar,
      slug: userSlug,
      isAdmin: userIsAdmin,
      isStaff: userIsStaff,
    },
  }
}
