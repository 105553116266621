import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  Box,
  SimpleGrid,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

import PrimaryTile from '../../elements/building/dashboard/primaryTile'
import InfoTile from '../../elements/building/dashboard/infoTile'
import SecondaryTile from '../../elements/building/dashboard/secondaryTile'
import FeedbackTile from '../../elements/building/dashboard/feedbackTile'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'

import Year from '../../assets/dashboard/year.png'
import YearWhite from '../../assets/dashboard/year1.png'
import Month from '../../assets/dashboard/month.png'
import MonthWhite from '../../assets/dashboard/month1.png'
import Week from '../../assets/dashboard/week.png'
import WeekWhite from '../../assets/dashboard/week1.png'
import Day from '../../assets/dashboard/day.png'
import DayWhite from '../../assets/dashboard/day1.png'
import OptiTwinLogo from '../../assets/Opti-Twin-logo.png'
import {
  BuildingDashboardGQL,
  DashboardPrimaryType,
  DashboardSecondaryType,
} from './dashboardWrapper'

type TabsType = {
  key: string
  value: string
  src: string
  srcWhite: string
  index: number
}

const dashboardTabs: TabsType[] = [
  {
    key: 'YEAR',
    value: 'annual consumption',
    src: Year,
    srcWhite: YearWhite,
    index: 0,
  },
  {
    key: 'MONTH',
    value: 'monthly consumption',
    src: Month,
    srcWhite: MonthWhite,
    index: 1,
  },
  {
    key: 'WEEK',
    value: 'weekly consumption',
    src: Week,
    srcWhite: WeekWhite,
    index: 2,
  },
  {
    key: 'DAY',
    value: '24hr consumption',
    src: Day,
    srcWhite: DayWhite,
    index: 3,
  },
]

const MainDashboard = ({
  dashboardSlug: dashboardData,
}: BuildingDashboardGQL) => {
  console.log('MainDashboard', dashboardData.data)
  const params = useParams()
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const buildingSlug: string = params['buildingSlug'] ?? 'unknown building'
  const [currentTab, setCurrentTab] = useState<number>(0)
  const currentData = dashboardData.data[currentTab]
  const primaryData: DashboardPrimaryType[] | undefined = currentData?.primary
  const secondaryData: DashboardSecondaryType[] | undefined =
    currentData?.secondary
  const titleMarginBottom = is4k ? 50 : 20
  const titleRef = useRef<HTMLDivElement>(null)
  const tabsRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const occupiedHeight =
    (titleRef.current?.getBoundingClientRect().height ?? 0) +
    (tabsRef.current?.getBoundingClientRect().height ?? 0) +
    40 + // padding
    titleMarginBottom

  useEffect(() => {
    const timer = setInterval(() => {
      let tabIndex = (currentTab + 1) % 4
      setCurrentTab(tabIndex)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <SimpleGrid p={'20px'} bg={'purple.radial'} ref={parentRef}>
      <Box
        ref={titleRef}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={{ md: '20px', '2xl': is4k ? '50px' : '20px' }}
      >
        <Image
          src={OptiTwinLogo}
          alt={'logo.png'}
          maxW={{
            md: '150px',
            xl: '200px',
            '2xl': is1440p ? '250px' : is4k ? '350px' : '200px',
          }}
        />
        <Text
          color={'white'}
          fontSize={{
            md: '30px',
            xl: '50px',
            '2xl': is1440p ? '60px' : is4k ? '80px' : '50px',
          }}
        >
          {dashboardData?.name}
        </Text>
        <Image
          src={dashboardData?.occupierLogo}
          maxW={{
            md: '150px',
            lg: '200px',
            xl: '300px',
            '2xl': is1440p ? '350px' : is4k ? '600px' : '300px',
          }}
          alt={'occupier.png'}
        />
      </Box>
      <Box ref={tabsRef}>
        <Tabs
          size={'sm'}
          variant={'enclosed-colored'}
          align={'end'}
          mb={'4px'}
          index={currentTab}
        >
          <TabList borderBottom={'none'}>
            {dashboardTabs.map((datum: TabsType, i: number) => (
              <Tab
                key={datum.key}
                textTransform={'uppercase'}
                borderTopLeftRadius={!i ? '20px' : ''}
                border={'none'}
                bg={'white.gradient'}
                color={'#5327B9'}
                _selected={{
                  color: 'white',
                  bg: 'green.gradientTab',
                }}
                onClick={() => setCurrentTab(i)}
              >
                <Image
                  src={i === currentTab ? datum.srcWhite : datum.src}
                  height={{
                    md: '30px',
                    lg: '25px',
                    xl: '40px',
                    '2xl': is1440p ? '54px' : is4k ? '80px' : '40px',
                  }}
                  mr={'5px'}
                  alt={datum.key + '.png'}
                />
                <Text
                  fontSize={{
                    md: '11px',
                    lg: '10px',
                    xl: '14px',
                    '2xl': is1440p ? '21px' : is4k ? '34px' : '14px',
                  }}
                >
                  {datum.value}
                </Text>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Box>
        <Grid
          templateAreas={{
            base: `
            "primary1"
            "primary2"
            "primary3"
            "secondary1"
            "secondary2"
            "secondary3"
            "secondary4 "
            "secondary5"
            "secondary6"
            "info1"
            "feedback1"
            "feedback2"
            "info2"
            `,
            md: `
            "primary1 primary1 primary1"
            "primary2 primary2 primary2"
            "primary3 primary3 primary3"
            "secondary1 secondary2 secondary3"
            "secondary4 secondary5 secondary6"
            "info1 info1 info1"
            "feedback1 feedback1 feedback1"
            "feedback2 feedback2 feedback2"
            "info2 info2 info2"
            `,
            lg: `
        "primary1 primary1 primary2 primary2 primary3 primary3 info1 info1"
        "primary1 primary1 primary2 primary2 primary3 primary3 info1 info1"
        "primary1 primary1 primary2 primary2 primary3 primary3 info1 info1"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary1 secondary2"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary1 secondary2"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary1 secondary2"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary3 secondary4"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary3 secondary4"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary3 secondary4"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary5 secondary6"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary5 secondary6"
        "primary1 primary1 primary2 primary2 primary3 primary3 secondary5 secondary6"
        "primary1 primary1 primary2 primary2 primary3 primary3 info2 info2"
        "feedback1 feedback1 feedback1 feedback2 feedback2 feedback2 info2 info2"
        "feedback1 feedback1 feedback1 feedback2 feedback2 feedback2 info2 info2"`,
          }}
          gridTemplateRows={{
            base: 'repeat(13, 1fr)',
            md: 'repeat(5,auto) repeat(4, 150px)',
            lg: 'repeat(15,1fr)',
          }}
          gridTemplateColumns={{
            base: '1fr',
            md: 'repeat(3,1fr)',
            lg: 'repeat(8,1fr)',
          }}
          gap={{ md: '5px', lg: '10px', '2xl': is4k ? '20px' : '10px' }}
          p={{
            md: '5px',
            lg: '10px',
            '2xl': is4k ? '20px' : '10px',
          }}
          borderWidth={'1px'}
          borderColor={'green.500'}
          borderTopLeftRadius={'10px'}
          color="blackAlpha.700"
          fontWeight="bold"
          minHeight={{ lg: `calc(100vh - ${occupiedHeight}px)` }}
        >
          {primaryData?.map((datum: DashboardPrimaryType, i: number) => (
            <GridItem bg="purple.gradient" area={`primary${i + 1}`} key={i}>
              <PrimaryTile
                type={datum.type}
                measurement={datum.measurement}
                target={datum.target}
                comment={datum.comment}
                comparison={datum.comparison}
              />
            </GridItem>
          ))}
          <GridItem p={3} bg="green.gradient" area={'info1'}>
            <InfoTile />
          </GridItem>
          {secondaryData?.map((datum: DashboardSecondaryType, i: number) => (
            <GridItem bg="purple.gradient" area={`secondary${i + 1}`} key={i}>
              <SecondaryTile
                type={datum.type}
                title={datum.title}
                measurement={datum.measurement}
                target={datum.target}
                comparison={datum.comparison}
              />
            </GridItem>
          ))}
          <GridItem bg="green.gradient" area={'info2'} p={3}>
            <InfoTile
              qrCode={`https://live.opti-twin.co.uk/building/${buildingSlug}/dashboard`}
            />
          </GridItem>
          <GridItem bg="white" area={'feedback1'}>
            <FeedbackTile
              contentSelection={'tile-1'}
              message={currentData?.infoMessage}
            />
          </GridItem>
          <GridItem bg="white" area={'feedback2'}>
            <FeedbackTile
              contentSelection={'tile-2'}
              message={currentData?.tryMessage}
            />
          </GridItem>
        </Grid>
      </Box>
    </SimpleGrid>
  )
}

export default MainDashboard
