import { Box, Text } from '@chakra-ui/react'

import CardIcons from './cardIcons'

type IconBlockType = {
  fontWeight: number | string
  fontSize: number | string | undefined
  iconType?: string
  height?: number | string | undefined
  width?: number | string | undefined
  text: string | undefined
}

const IconBlock = ({
  fontWeight,
  fontSize,
  iconType,
  text,
  width,
  height,
}: IconBlockType) => {
  return (
    <Box display={'flex'} alignItems={'center'} height={'100%'}>
      {iconType && width && (
        <CardIcons width={width} height={height || width} iconType={iconType} />
      )}
      <Text
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={'black'}
        lineHeight={1}
        ml={'15px'}
      >
        {text}
      </Text>
    </Box>
  )
}

export default IconBlock
