import {
  Box,
  Center,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import Comparison from '../../comparison/comparison'
import CardIcons from './cardIcons'
import CircleIndicator from './circleIndicator'

import { tightMapCalc } from '../../../utils/colorMaps/tightMap'
import { indicatorTypeData } from '../../../utils/indicators/indicators'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import { DashboardPrimaryType } from '../../../Pages/buildings/dashboardWrapper'

const PrimaryTile = ({
  type,
  measurement,
  target,
  comment,
  comparison,
}: DashboardPrimaryType) => {
  const percent = Math.ceil((measurement / target) * 100)
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const { color: iconColor } = tightMapCalc({
    value: percent,
    range: 150,
    upIsBetter: indicatorTypeData[type].upIsBetter,
  })
  const indicatorSize = useBreakpointValue({
    base: 100,
    md: 300,
    lg: 200,
    xl: 350,
    '2xl': is1440p ? 500 : is4k ? 800 : 350,
  })
  const iconSize = useBreakpointValue({
    base: 45,
    md: 55,
    lg: 35,
    xl: '60px',
    '2xl': is1440p ? 90 : is4k ? 160 : '60px',
  })
  const comparisonFontSize = useBreakpointValue({
    base: 45,
    md: 16,
    '2xl': is1440p ? 25 : is4k ? 35 : 16,
  })
  return (
    <Box
      p={'30px'}
      h={'100%'}
      display={'flex'}
      flexDirection={{ md: 'row', lg: 'column' }}
      alignItems={'center'}
      justifyContent={'center'}
      fontWeight={400}
      lineHeight={1}
    >
      <Box
        position={'relative'}
        height={{ lg: '55%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircleIndicator percent={percent} size={indicatorSize} />
        <Box
          position={'absolute'}
          top={{
            lg: 'calc(50% - 45px)',
            xl: 'calc(50% - 85px)',
            '2xl': is4k ? 'calc(50% - 145px)' : 'calc(50% - 85px)',
          }}
          left={{ lg: 'calc(50% - 72px)', xl: 'calc(50% - 132px)' }}
          color={'white'}
          width={{ md: '205px', lg: '135px', xl: '250px' }}
        >
          <Center>
            <CardIcons iconType={type} color={iconColor} width={iconSize} />
            <Text
              fontSize={{
                md: '60px',
                lg: '40px',
                xl: '65px',
                '2xl': is1440p ? '90px' : is4k ? '160px' : '65px',
              }}
              ml={'10px'}
            >
              {percent}%
            </Text>
          </Center>
          <Center>
            <Text
              textAlign={'center'}
              fontSize={{
                md: '30px',
                lg: '20px',
                xl: '45px',
                '2xl': is1440p ? '55px' : is4k ? '75px' : '45px',
              }}
              mt={'25px'}
            >
              {indicatorTypeData[type].name}
            </Text>
          </Center>
        </Box>
      </Box>
      <Box
        borderTop={{
          lg: `1px solid ${iconColor}`,
          '2xl': is4k ? `3px solid ${iconColor}` : `1px solid ${iconColor}`,
        }}
        borderLeft={{ md: `1px solid ${iconColor}`, lg: 'none' }}
        p={'10px'}
        color={'white'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        mt={{ lg: 5 }}
        ml={{ md: 5 }}
        height={{ md: '100%', lg: '45%' }}
      >
        <Box
          display={'flex'}
          flexDirection={{ md: 'column', lg: 'row' }}
          justifyContent={'center'}
          alignItems={{ md: 'center', lg: 'end' }}
        >
          <Box justifySelf={'flex-end'}>
            <Text
              fontSize={{
                md: '16px',
                lg: '10px',
                xl: '16px',
                '2xl': is1440p ? '20px' : is4k ? '35px' : '16px',
              }}
              w={{ md: '100%', lg: 'min-content' }}
              align={'right'}
              mb={{ md: '20px', lg: 0 }}
              textTransform={'uppercase'}
            >
              {indicatorTypeData[type].description}
            </Text>
          </Box>

          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={{ md: 'center', lg: 'end' }}
          >
            <Text
              fontSize={{
                md: '78px',
                lg: '50px',
                xl: '78px',
                '2xl': is1440p ? '100px' : is4k ? '170px' : '78px',
              }}
              lineHeight={0.8}
            >
              {measurement}
            </Text>
            <Text
              fontSize={{
                md: '30px',
                lg: '14px',
                xl: '30px',
                '2xl': is1440p ? '45px' : is4k ? '75px' : '30px',
              }}
            >
              {indicatorTypeData[type].unit}
            </Text>
          </Box>
        </Box>
        <Text
          fontSize={{
            md: '17px',
            lg: '12px',
            xl: '17px',
            '2xl': is1440p ? '25px' : is4k ? '38px' : '17px',
          }}
          m={'20px 0'}
        >
          {comment}
        </Text>
        <Box justifySelf={'end'}>
          <Comparison
            p={'5px 0'}
            fontSize={comparisonFontSize}
            text={comparison}
            color={'white'}
            centerAlign={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PrimaryTile
