import { Box, Heading, Text } from '@chakra-ui/react'

const DataQuality = () => {
  return (
    <Box
      py={6}
      pl={3}
      pr={6}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box background={'white'} p={4} h="100%">
        <Heading as="h3" size="md">
          Data Quality
        </Heading>
        <Text pt={4}>
          We are monitoring the data feed from this building, any issues will be
          notified to the project team.
        </Text>
      </Box>
    </Box>
  )
}

export default DataQuality
