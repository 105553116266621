import {
  Grid,
  GridItem,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import IconBlock from './iconBlock'

import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

type FeedbackTileType = {
  contentSelection: string
  message: string | undefined
}

type TileDetailsType = {
  [key: string]: {
    iconLeft: string
    textLeft: string
  }
}

const tileDetails: TileDetailsType = {
  'tile-1': {
    iconLeft: 'Question',
    textLeft: 'How are we doing',
  },
  'tile-2': {
    iconLeft: 'Info',
    textLeft: 'Why not...',
  },
}

const FeedbackTile = ({ contentSelection, message }: FeedbackTileType) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const titleFontSize = useBreakpointValue({
    base: '12px',
    md: '30px',
    lg: '14px',
    xl: '30px',
    '2xl': is1440p ? '45px' : is4k ? '60px' : '30px',
  })
  const descFontSize = useBreakpointValue({
    base: '12px',
    md: '16px',
    lg: '8px',
    xl: '16px',
    '2xl': is1440p ? '22px' : is4k ? '34px' : '16px',
  })
  const primaryIconSize = useBreakpointValue({
    base: '12px',
    md: '42px',
    lg: '30px',
    xl: '42px',
    '2xl': is1440p ? '60px' : is4k ? '90px' : '42px',
  })
  const secondaryIconWidth = useBreakpointValue({
    base: '12px',
    md: 45,
    lg: 35,
    xl: 45,
    '2xl': is1440p ? '60px' : is4k ? '90px' : 45,
  })
  const secondaryIconHeight = useBreakpointValue({
    base: '12px',
    md: 75,
    lg: '60px',
    xl: 75,
    '2xl': is1440p ? '120px' : is4k ? '180px' : 75,
  })
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      gap={'10px'}
      height={'100%'}
      padding={'10px 15px'}
    >
      <GridItem>
        <IconBlock
          fontWeight={'bold'}
          fontSize={titleFontSize}
          width={primaryIconSize}
          iconType={tileDetails[contentSelection].iconLeft}
          text={tileDetails[contentSelection].textLeft}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <IconBlock
          fontWeight={'400'}
          fontSize={descFontSize}
          width={secondaryIconWidth}
          height={secondaryIconHeight}
          iconType={contentSelection === 'tile-1' ? 'Speedo' : undefined}
          text={message}
        />
      </GridItem>
    </Grid>
  )
}

export default FeedbackTile
