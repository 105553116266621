import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Loader from '../../elements/loader'
import MainDashboard from './mainDashboard'
import SimpleDashWrapper from './simpleDash'

export type DashboardPrimaryType = {
  comment: string
  comparison: string
  measurement: number
  target: number
  type: string
}

export type DashboardSecondaryType = {
  measurement: number
  target: number
  title: string | null
  type: string
  comparison: string
}

export type DashboardRenewablesType = {
  measurement: number
  target: number
  title: string | null
  type: string
  comparison: string
}

export type BuildingDashboardDataType = {
  timescale: string
  currentConsumption: number
  currentGeneration: number
  infoMessage: string
  lifetimeCarbonSavings: number
  lifetimeCarbonSavingsUnits: string
  tryMessage: string
  primary: DashboardPrimaryType[]
  secondary: DashboardSecondaryType[]
  renewables: DashboardRenewablesType
}

export type BuildingDashboardGQL = {
  dashboardSlug: {
    buildingId: number
    name: string
    slug: string
    useSimpleDash: boolean
    occupierName: string
    occupierLogo: string
    data: BuildingDashboardDataType[]
  }
}

const GET_BUILDING = gql`
  query dashboardSlug($slug: String!) {
    dashboardSlug(slug: $slug) {
      buildingId
      name
      slug
      useSimpleDash
      occupierName
      occupierLogo
      data {
        timescale
        primary {
          type
          measurement
          target
          comment
          comparison
        }
        secondary {
          type
          title
          measurement
          target
          comparison
        }
        renewables {
          type
          title
          measurement
          target
          comparison
        }
        tryMessage
        infoMessage
        currentConsumption
        currentGeneration
        lifetimeCarbonSavings
        lifetimeCarbonSavingsUnits
      }
    }
  }
`

const DashboardWrapper = () => {
  const params = useParams()
  const buildingSlug: string = params['buildingSlug'] ?? 'unknown building'
  const reverseChronologicalOrder: { [key: string]: number } = {
    YEAR: 4,
    MONTH: 3,
    WEEK: 2,
    DAY: 1,
  }
  const { data, loading } = useQuery<BuildingDashboardGQL>(GET_BUILDING, {
    variables: {
      slug: buildingSlug,
    },
  })

  let orderedData: any //  should assign a correct type
  if (!loading) {
    orderedData = {
      dashboardSlug: {
        ...data?.dashboardSlug,
        data: [...(data?.dashboardSlug.data || [])],
      },
    }

    if (Array.isArray(orderedData.dashboardSlug.data)) {
      orderedData.dashboardSlug.data.sort(
        (a: BuildingDashboardDataType, b: BuildingDashboardDataType) =>
          reverseChronologicalOrder[`${b.timescale}`] >
          reverseChronologicalOrder[`${a.timescale}`]
            ? 1
            : -1,
      )
    }
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      {orderedData?.dashboardSlug.useSimpleDash ? (
        <SimpleDashWrapper dashboardSlug={orderedData?.dashboardSlug} />
      ) : (
        <MainDashboard dashboardSlug={orderedData?.dashboardSlug} />
      )}
    </>
  )
}

export default DashboardWrapper
