import { Image } from '@chakra-ui/react'

interface PictureProps {
  src: string
  alt: string
  h?: string // kept this as an optionl property as picture can be used without height
}

const Picture = ({ src, alt, h }: PictureProps) => {
  return <Image fit={'cover'} src={src} alt={alt} h={h} />
}

export default Picture
