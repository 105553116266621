import { Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import Root from './layouts/root'
import Contact from './Pages/contact'
import ErrorPage from './Pages/error'
import Home from './Pages/home'
import Support from './Pages/support'
import Terms from './Pages/terms'
import Privacy from './Pages/privacy'
import Buildings from './Pages/buildings/buildings'
import WrappedBuilding from './Pages/buildings/building'
import { RequireAuth } from './hooks/use-auth'
import SignIn from './Pages/signIn'
import SignOut from './Pages/signOut'
import NewBuilding from './Pages/buildings/new'
import Loader from './elements/loader'
import DashboardWrapper from './Pages/buildings/dashboardWrapper'
import { WrappedBuildingDetails } from './Pages/buildings/buildingDetails'


const router = createBrowserRouter([
  {
    path: '/buildings/:buildingSlug/dashboard',
    element: <DashboardWrapper />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: 'signin', element: <SignIn /> },
      { path: 'signout', element: <SignOut /> },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'privacy',
        element: (
          <RequireAuth>
            <Privacy />
          </RequireAuth>
        ),
      },
      {
        path: 'buildings',
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <Suspense fallback={<Loader />}>
                  <Buildings />
                </Suspense>
              </RequireAuth>
            ),
          },
          {
            path: 'new',
            element: (
              <RequireAuth>
                <Suspense fallback={<Loader />}>
                  <NewBuilding />
                </Suspense>
              </RequireAuth>
            ),
          },
          {
            path: ':buildingSlug',
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<Loader />}>
                    <WrappedBuilding />
                  </Suspense>
                ),
              },
              { path: 'update', element: <p>Update building form here</p> },
              {
                path: 'delete',
                element: <p>Replace with delete and redirect</p>,
              },
              { path: 'details', element: <WrappedBuildingDetails /> },
              // { path: 'dashboard', element: <Dashboard /> },
              {
                path: 'channels',
                children: [
                  {
                    index: true,
                    element: <p>Channel List here</p>,
                  },
                  { path: 'new', element: <p>New channel form</p> },
                  {
                    path: ':channelSlug',
                    children: [
                      { index: true, element: <p>channel data page</p> },
                      {
                        path: 'update',
                        element: <p>channel edit form here</p>,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])

export default router
