import { GridItem, Image, SimpleGrid } from '@chakra-ui/react'

type HeroProps = {
  imageUrl: string
  altText: string
  height?: 'reg' | 'tall'
}

const Hero = ({ imageUrl, altText, height = 'reg' }: HeroProps) => {
  const h =
    height === 'tall'
      ? {}
      : { base: '25vh', sm: '25vh', md: '30vh', lg: '40vh' }
  return (
    <SimpleGrid alignItems={'center'} columns={12}>
      <GridItem
        colSpan={[1, 1, 1, 1, 2]}
        display={{ base: 'none', sm: 'none', md: 'flex' }}
      ></GridItem>
      <GridItem colSpan={[12, 12, 10, 10, 8]}>
        <Image
          w={'full'}
          h={h}
          src={imageUrl}
          alt={altText}
          objectFit={'cover'}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default Hero
