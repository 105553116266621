import {
  Center,
  ComponentWithAs,
  GridItem,
  IconProps,
  SimpleGrid,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import WholeBuilidng from '../../elements/icons/wholeBuilding'
import PfHeadlineItem from '../../elements/pfHeadlineItem'

export type PfHeadlineItemProps = {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
}

type PfHeadlineProps = {
  items?: PfHeadlineItemProps[]
  loading: boolean
}

// Note: use valid translation strings in loading element
const loadingItem: PfHeadlineItemProps = {
  Icon: WholeBuilidng,
  label: 'buildingEnergy',
  value: 123.3,
  target: 140,
  units: 'MWh',
  description: 'totalEnergyUsed',
  upIsBetter: false,
}

const PfHeadline = ({ items, loading }: PfHeadlineProps) => {
  const headlineItems: PfHeadlineItemProps[] =
    loading || items === undefined
      ? Array(4)
          .fill(loadingItem)
          .map((placeholder, idx) => ({
            ...placeholder,
            slug: `loading-placeholder-${idx}`,
          }))
      : items
  return (
    <SimpleGrid
      backgroundColor={'gray.300'}
      pt={4}
      pb={5}
      pl="0.5em"
      pr="0.5em"
      spacing={5}
      columns={[24]}
      height="100%"
    >
      <GridItem
        colSpan={[2, 2, 2, 2, 4]}
        display={{ base: 'none', sm: 'none', md: 'flex' }}
      ></GridItem>
      {headlineItems.slice(0, 4).map((item, idx) => (
        <Fragment key={`Item${idx}`}>
          <GridItem rowSpan={1} colSpan={[12, 12, 10, 5, 4, 4]}>
            <Center>
              <PfHeadlineItem {...item} loading={loading} />
            </Center>
          </GridItem>
          {idx === 1 && (
            <>
              <GridItem
                colSpan={[2, 2, 2, 2, 4]}
                display={{ base: 'none', sm: 'none', md: 'flex', lg: 'none' }}
              ></GridItem>
              <GridItem
                colSpan={[2, 2, 2, 2, 4]}
                display={{ base: 'none', sm: 'none', md: 'flex', lg: 'none' }}
              ></GridItem>
            </>
          )}
        </Fragment>
      ))}
    </SimpleGrid>
  )
}

export default PfHeadline
