import { Box, Heading } from '@chakra-ui/react'
//import { GradientPinkBlue } from '@visx/gradient'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { Group } from '@visx/group'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import { animated, to, useTransition } from '@react-spring/web'

export interface EnergyDistProps {
  loading: boolean
  data?: {
    duration: string
    use: {
      type: string
      label: string
      target: number
      value: number
      color: string
    }[]
  }
}

const EnergyDist = ({ data, loading }: EnergyDistProps) => {
  if (loading || data === undefined) {
    return (
      <Box
        py={6}
        pl={3}
        pr={6}
        background={'gray.300'}
        textColor={'purple.600'}
        h="100%"
      >
        <Box background={'white'} p={4} h="100%">
          <Heading as="h3" size="md">
            Energy Use
          </Heading>
          <Box height="300px" width="100px">
            loading...
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      py={6}
      pl={3}
      pr={6}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box background={'white'} p={4} h="100%">
        <Heading as="h3" size="md">
          Energy Distribution
        </Heading>
        <Box height="300px" width="100%">
          <ParentSize>
            {({ width, height }) => {
              const margin = { top: 10, bottom: 10, left: 10, right: 10 }
              const innerWidth = width - margin.top - margin.bottom
              const innerHeight = height - margin.left - margin.right
              const centerX = innerWidth / 2
              const centerY = innerHeight / 2
              const radius = Math.min(innerWidth, innerHeight) / 2
              const thickness = 50

              return (
                <svg width={width} height={height}>
                  {/* <GradientPinkBlue id="visx-pie-gradient" /> */}
                  <rect rx={14} width={width} height={height} fill={'white'} />
                  <Group
                    top={centerY + margin.top}
                    left={centerX + margin.left}
                  >
                    <Pie
                      data={data.use}
                      pieValue={(d) => d.value}
                      outerRadius={radius}
                      innerRadius={radius - thickness}
                      padAngle={0.005}
                      cornerRadius={3}
                    >
                      {(pie) => (
                        <AnimatedPie
                          {...pie}
                          getKey={(arc) => arc.data.label}
                          getColor={(arc) => arc.data.color}
                          onClickDatum={(_d) => {}}
                        />
                      )}
                    </Pie>
                  </Group>
                </svg>
              )
            }}
          </ParentSize>
        </Box>
      </Box>
    </Box>
  )
}

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
})

type AnimatedPieProps<T> = ProvidedProps<T> & {
  animate?: boolean
  getKey: (d: PieArcDatum<T>) => string
  getColor: (d: PieArcDatum<T>) => string
  onClickDatum: (d: PieArcDatum<T>) => void
  delay?: number
}

function AnimatedPie<T>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}: AnimatedPieProps<T>) {
  const transitions = useTransition<PieArcDatum<T>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc)
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1

    return (
      <g key={key}>
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="black"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={9}
              textAnchor="middle"
              pointerEvents="none"
            >
              {getKey(arc)}
            </text>
          </animated.g>
        )}
      </g>
    )
  })
}

export default EnergyDist
