import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Home,
  LogIn,
  LogOut,
  //User
} from 'react-feather'
import { useTranslation } from 'react-i18next'

import OptiTwinLogo from '../../assets/Opti-Twin-logo.png'
import { useAuth } from '../../hooks/use-auth'

const NavBar = () => {
  const { t } = useTranslation('nav')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const auth = useAuth()

  const loggedIn = !(auth.user.firstName === '' && auth.user.lastName === '')

  return (
    <>
      <Box
        as="nav"
        px={4}
        bgGradient="linear(to-r, purple.800, purple.300, purple.800)"
      >
        <SimpleGrid
          h={24}
          alignItems="centre"
          justifyContent="space-between"
          columns={12}
        >
          <GridItem
            colSpan={[1, 1, 1, 1, 2]}
            display={{ base: 'none', sm: 'none', md: 'flex' }}
          ></GridItem>
          <GridItem colSpan={[7, 5, 3, 3, 2, 2]}>
            <Image maxH={95} src={OptiTwinLogo} alt="Opti-Twin Logo" />
          </GridItem>
          <GridItem colSpan={[3, 5, 5, 4]}></GridItem>
          <GridItem colSpan={[2, 2]}>
            <Flex alignItems={'center'} justifyContent="flex-end" height="100%">
              <Stack direction="row" spacing={7}>
                <Menu onOpen={onOpen} onClose={onClose}>
                  <MenuButton
                    as={Button}
                    variant="link"
                    cursor="pointer"
                    minW={0}
                    boxShadow="lg"
                  >
                    {isOpen ? (
                      <CloseIcon
                        w={[6, 8, 8, 10]}
                        h={[6, 8, 8, 10]}
                        color="gray.100"
                      />
                    ) : (
                      <HamburgerIcon
                        w={[8, 10, 10, 12]}
                        h={[8, 10, 10, 12]}
                        color="gray.100"
                      />
                    )}
                  </MenuButton>
                  {loggedIn ? (
                    <MenuList alignItems="center">
                      <br />
                      <Center>
                        <Avatar size="2xl" src={auth.user.avatar} />
                      </Center>
                      <br />
                      <Center>
                        <Text>
                          {auth.user.firstName} {auth.user.lastName}
                        </Text>
                      </Center>
                      <MenuDivider />
                      <MenuItem>
                        <Link as={RouterLink} to={'buildings'}>
                          <Icon as={Home} pr={1} size={'lg'} />{' '}
                          {t('yourBuildings')}
                        </Link>
                      </MenuItem>
                      {/*<MenuItem>
                        <Link as={RouterLink} to={`/user/${auth.user.slug}`}>
                          <Icon as={User} pr={1} size={'lg'} /> {t('account')}
                        </Link>
                  </MenuItem>*/}
                      <MenuItem>
                        <Link as={RouterLink} to={'/signout'}>
                          <Icon as={LogOut} pr={1} size="lg" /> {t('signOut')}
                        </Link>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList alignItems="center">
                      <br />
                      <MenuItem>
                        <Link as={RouterLink} to={'/'}>
                          <Icon as={Home} pr={1} size={'lg'} /> {t('home')}
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link as={RouterLink} to={'signin'}>
                          <Icon as={LogIn} pr={1} size={'lg'} /> {t('signIn')}
                        </Link>
                      </MenuItem>
                    </MenuList>
                  )}
                </Menu>
              </Stack>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Box>
    </>
  )
}

export default NavBar
