import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/footer'
import NavBar from '../components/navBar/navBar'
//import { useAuth } from '../hooks/use-auth'

const Root = () => {
  //const auth = useAuth()
  //console.log(auth)
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  )
}

export default Root
