import {
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'

const Loader = () => {
  return (
    <Modal
      isCentered
      isOpen={true}
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <Center h="300px">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="purple.500"
            size="xl"
          />
        </Center>
      </ModalContent>
    </Modal>
  )
}

export default Loader
