import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import { DashboardSecondaryType } from '../../../Pages/buildings/dashboardWrapper'
import { tightMapCalc } from '../../../utils/colorMaps/tightMap'
import {
  IndicatorType,
  indicatorTypeData,
} from '../../../utils/indicators/indicators'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import CardIcons from './cardIcons'

const SecondaryTile = ({
  type,
  measurement,
  title,
  target,
}: DashboardSecondaryType) => {
  const {
    type: iconType,
    name,
    unit,
    description,
    upIsBetter,
  }: IndicatorType[string] = indicatorTypeData[type]
  const percentage = target ? Math.round((measurement / target) * 100) : 100
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const { color: iconColor } = tightMapCalc({
    value: percentage,
    range: 150,
    upIsBetter,
  })
  const [isDisplayPercentage, setDisplay] = useState(false)
  const iconSize = useBreakpointValue({
    base: 6,
    md: 8,
    lg: 6,
    xl: 8,
    '2xl': is1440p ? '60px' : is4k ? '80px' : 8,
  })

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplay(!isDisplayPercentage)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <Box
      h={'100%'}
      color={'white'}
      fontWeight={400}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      padding={'20px'}
    >
      <Grid templateColumns={'repeat(5,1fr)'} mb={'15px'}>
        <GridItem colStart={2} colEnd={3}>
          <CardIcons iconType={iconType} color={iconColor} width={iconSize} />
        </GridItem>
        <GridItem colStart={3} colEnd={6} alignSelf={'center'}>
          <Text
            fontSize={{
              md: '18px',
              lg: '11px',
              xl: '18px',
              '2xl': is1440p ? '25px' : is4k ? '36px' : '18px',
            }}
            ml={{
              md: '10px',
              '2xl': is4k ? '25px' : '10px',
            }}
            lineHeight={1}
            maxW={'min-content'}
          >
            {title ?? name}
          </Text>
        </GridItem>
      </Grid>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          textTransform={'uppercase'}
          fontSize={{
            md: '14px',
            lg: '8px',
            xl: '14px',
            '2xl': is1440p ? '18px' : is4k ? '28px' : '14px',
          }}
        >
          {description}
        </Text>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Text
            fontSize={{
              md: '46px',
              lg: '35px',
              xl: '46px',
              '2xl': is1440p ? '60px' : is4k ? '85px' : '46px',
            }}
            lineHeight={1}
          >
            {isDisplayPercentage ? measurement : percentage}
          </Text>
          <Text
            fontSize={{
              md: '22px',
              lg: '14px',
              xl: '24px',
              '2xl': is1440p ? '30px' : is4k ? '40px' : '24px',
            }}
            ml={1}
            alignSelf={'end'}
          >
            {isDisplayPercentage ? unit : '%'}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default SecondaryTile
