import { gql, useQuery } from '@apollo/client'
import { ComponentWithAs, Grid, GridItem, IconProps } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import PfHeadline, {
  PfHeadlineItemProps,
} from '../../components/pfHeadline/pfHeadline'
import DataQuality from '../../elements/building/dataQuality'
import EnergyDist, { EnergyDistProps } from '../../elements/building/energyDist'
import EnergyUse from '../../elements/building/energyUse'
import KeyFacts from '../../elements/building/keyFacts'
import Picture from '../../elements/building/picture'
import ReportRequest from '../../elements/building/reportsRequest'
import CO2 from '../../elements/icons/co2'
import Renewables from '../../elements/icons/renewables'
import WaterUsage from '../../elements/icons/waterUsage'
import WholeBuilidng from '../../elements/icons/wholeBuilding'
import Title from '../../elements/title'
import DefaultImage from '../../assets/Default-image.png'

interface BuildingWhereUniqueInput {
  where: {
    id?: number
    projectRef?: string
    slug?: string
  }
  slug: string
}

type BuildingGQL = {
  id: number
  createdAt: Date
  updatedAt: Date
  name: string
  slug: string
  picture: string
  epcRating: string
  type: {
    type: string
  }
  floorArea: string
  staffAdmin: {
    slug: string
    firstName: string
    lastName: string
  }
  client: {
    name: string
    slug: string
  }
  clientAdmin: {
    slug: string
    firstName: string
    lastName: string
  }
  portfolio: {
    type: string
    measurement: number
    target: number
  }[]
}

type DashGQL = {
  dashboard: {
    name: string
    data: {
      timescale: string
      primary: {
        measurement: number
        target: number
        type: string
      }[]
      secondary: {
        measurement: number
        target: number
        title: string | null
        type: string
      }
    }[]
  }
}

type GetBuildingData = {
  building: BuildingGQL
  dashboard: DashGQL
}

const BUILDING_QUERY = gql`
  query getBuilding($where: BuildingWhereUniqueInput!, $slug: String!) {
    building(where: $where) {
      id
      createdAt
      updatedAt
      name
      slug
      picture
      epcRating
      type {
        type
      }
      floorArea
      staffAdmin {
        slug
        firstName
        lastName
      }
      client {
        name
        slug
      }
      clientAdmin {
        slug
        firstName
        lastName
      }
      portfolio {
        type
        measurement
        target
      }
    }
    dashboardSlug(slug: $slug) {
      name
      data {
        timescale
        primary {
          measurement
          target
          type
        }
        secondary {
          measurement
          target
          title
          type
        }
      }
    }
  }
`

export type BuildingProps = {
  loading: boolean
  buildingName?: string
  buildingPicURL?: string
  leadEngName?: string
  clientName?: string
  clientLeadName?: string
  headlineItems?: PfHeadlineItemProps[]
  plotData?: EnergyDistProps
}

export const Building = ({
  loading,
  buildingName,
  buildingPicURL,
  leadEngName,
  clientName,
  clientLeadName,
  headlineItems,
  plotData,
}: BuildingProps) => {
  return (
    <>
      <Title loading={loading}>{buildingName}</Title>
      <Grid
        templateAreas={{
          base: ` "picture"
                  "info"
                  "head"
                  "head"
                  "liveFacts"
                  "dataQual"
                  "energyUse"
                  "energyDist"
                  "empty1"
                  "empty2"`,
          md: ` "info info picture empty2"
                "empty1 head head empty2"
                "empty1 liveFacts dataQual empty2"
                "empty1 energyUse energyDist empty2" `,
        }}
        gridTemplateColumns={{ base: '1fr', md: '1fr 2fr 2fr 1fr' }}
        gridTemplateRows={'1fr, 600px, 400px, 1fr'}
      >
        <GridItem
          area="empty1"
          display={{ base: 'none', md: 'flex' }}
        ></GridItem>
        <GridItem
          area="empty2"
          display={{ base: 'none', md: 'flex' }}
        ></GridItem>
        <GridItem area="info">
          <KeyFacts
            loading={loading}
            leadEngName={`${leadEngName}`}
            clientName={clientName}
            clientLeadName={`${clientLeadName}`}
          />
        </GridItem>
        <GridItem area="picture">
          {loading ? null : (
            <Picture
              src={buildingPicURL || DefaultImage}
              alt={buildingName || ''}
              h={!buildingPicURL ? '350' : ''}
            />
          )}
        </GridItem>
        <GridItem area="head">
          <PfHeadline items={headlineItems} loading={loading} />
        </GridItem>
        <GridItem area="liveFacts">
          <ReportRequest />
        </GridItem>
        <GridItem area="dataQual">
          <DataQuality />
        </GridItem>
        <GridItem area="energyUse">
          <EnergyUse data={plotData?.data} loading={loading} />
        </GridItem>
        <GridItem area="energyDist">
          <EnergyDist data={plotData?.data} loading={loading} />
        </GridItem>
      </Grid>
    </>
  )
}

const WrappedBuilding = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown builidng'
  const {
    data,
    loading: QueryLoading,
    //error,
  } = useQuery<GetBuildingData, BuildingWhereUniqueInput>(BUILDING_QUERY, {
    variables: { where: { slug: buildingSlug }, slug: buildingSlug },
  })

  const loading = QueryLoading

  const headlineItems: PfHeadlineItemProps[] =
    loading || !data
      ? []
      : data?.building.portfolio.map((pfItem) => {
          const specs: {
            Icon: ComponentWithAs<'svg', IconProps>
            label: string
            units: string
            description: string
            upIsBetter: boolean
          } =
            pfItem.type === 'WholeBuilding'
              ? {
                  Icon: WholeBuilidng,
                  label: 'buildingEnergy',
                  units: 'kWh',
                  description: 'totalEnergyUsed',
                  upIsBetter: false,
                }
              : pfItem.type === 'CarbonDioxide'
              ? {
                  Icon: CO2,
                  label: 'carbonDioxide',
                  units: 'tCO<sub>2</sub>',
                  description: 'totalEmissions',
                  upIsBetter: false,
                }
              : pfItem.type === 'WaterUsage'
              ? {
                  Icon: WaterUsage,
                  label: 'water',
                  units: 'm<sup>3</sup>',
                  description: 'totalWaterUsed',
                  upIsBetter: false,
                }
              : pfItem.type === 'Renewables'
              ? {
                  Icon: Renewables,
                  label: 'renewables',
                  units: 'kWh',
                  description: 'totalGenerated',
                  upIsBetter: true,
                }
              : {
                  Icon: WholeBuilidng,
                  label: 'Unknown',
                  units: '',
                  description: 'unknown total',
                  upIsBetter: false,
                }
          return {
            ...specs,
            value: pfItem.measurement,
            target: pfItem.target,
          }
        })

  const plotData: EnergyDistProps = {
    loading: loading,
    data: {
      duration: 'year',
      use: [
        {
          type: 'light',
          label: 'Light',
          target: 120000,
          value: 70000,
          color: '#a79dc0',
        },
        {
          type: 'heat',
          label: 'Heat',
          target: 120000,
          value: 150000,
          color: '#372e70',
        },
        {
          type: 'cool',
          label: 'Cool',
          target: 120000,
          value: 110000,
          color: '#8a2b56',
        },
        {
          type: 'vent',
          label: 'Vent',
          target: 70000,
          value: 4000,
          color: '#91b706',
        },
        {
          type: 'hotWater',
          label: 'Hot Water',
          target: 120000,
          value: 30000,
          color: '#2b8a6b',
        },
        {
          type: 'smallPower',
          label: 'Socket',
          target: 120000,
          value: 170000,
          color: '#fbcd69',
        },
        {
          type: 'process',
          label: 'Process',
          target: 120000,
          value: 340000,
          color: '#ec6361',
        },
      ],
    },
  }

  return (
    <Building
      loading={loading}
      buildingName={data?.building.name}
      buildingPicURL={data?.building.picture}
      leadEngName={`${data?.building.staffAdmin.firstName} ${data?.building.staffAdmin.lastName}`}
      clientName={data?.building.client.name}
      clientLeadName={`${data?.building.clientAdmin.firstName} ${data?.building.clientAdmin.lastName}`}
      headlineItems={headlineItems}
      plotData={plotData}
    />
  )
}

export default WrappedBuilding
