import {
  Box,
  Image,
  Text,
  TextProps,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import Comparison from '../../comparison/comparison'
import NumberCard from './numberCard'
import { ColorCard } from '../../../utils/valueInference/colorCard'

import Process from '../../../assets/SimpleDash/secondary/01-Process.svg'
import Lighting from '../../../assets/SimpleDash/secondary/02-Lighting.svg'
import Heating from '../../../assets/SimpleDash/secondary/03-Heating.svg'
import SmallPower from '../../../assets/SimpleDash/secondary/04-Socket.svg'
import Ventilation from '../../../assets/SimpleDash/secondary/05-Ventilation.svg'
import Cooling from '../../../assets/SimpleDash/secondary/06-Cooling.svg'
import PV from '../../../assets/SimpleDash/secondary/07-PV.svg'
import Battery from '../../../assets/SimpleDash/secondary/08-Battery.svg'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import { indicatorTypeData } from '../../../utils/indicators/indicators'

type secondaryCardProps = {
  type: string
  target: number | undefined
  measurement: number | undefined
  comparison?: string
}

type cardImageObjProps = {
  title: string
  src: string
}

type cardImageType = {
  ProcessPower: cardImageObjProps
  Lighting: cardImageObjProps
  Heating: cardImageObjProps
  SmallPower: cardImageObjProps
  Ventilation: cardImageObjProps
  Cooling: cardImageObjProps
  PV: cardImageObjProps
  Battery: cardImageObjProps
  [key: string]: cardImageObjProps
}

type NumberCardTitleProps = {
  align: TextProps['align']
  content: string
  textTransform?: TextProps['textTransform']
}

//Variables names are PascalCased for easy access of images
const cardImage: cardImageType = {
  ProcessPower: { title: 'Process Energy', src: Process },
  Lighting: { title: 'Lighting Energy', src: Lighting },
  Heating: { title: 'Heating Energy', src: Heating },
  SmallPower: { title: 'Socket Power', src: SmallPower },
  Ventilation: { title: 'Ventilation Energy', src: Ventilation },
  Cooling: { title: 'Cooling Energy', src: Cooling },
  PV: { title: 'On site PV Generation', src: PV },
  Battery: { title: 'On site Battery Storage', src: Battery },
}

const isRenewables = (type: secondaryCardProps['type']): boolean => {
  return ['PV', 'Battery'].includes(type)
}

const NumberCardTitle = ({
  align,
  content,
  textTransform = 'none',
}: NumberCardTitleProps) => {
  const [is4k, is2k] = useMediaQuery(mediaQueries)
  return (
    <Text
      noOfLines={1}
      fontSize={{
        base: 12,
        md: 16,
        lg: 9,
        xl: 14,
        '2xl': is4k ? '34px' : is2k ? '16px' : 14,
      }}
      align={align}
      textTransform={textTransform}
    >
      {content}
    </Text>
  )
}
const SecondaryCard = ({
  type,
  target,
  measurement,
  comparison,
}: secondaryCardProps) => {
  const upIsBetter = isRenewables(type)
  const [is4k, is2k] = useMediaQuery(mediaQueries)
  const comparisonFontSize = useBreakpointValue({
    base: 16,
    md: 16,
    '2xl': is4k ? 33 : is2k ? 23 : 17,
  })
  const NumberCardTitleFont = useBreakpointValue({
    base: 45,
    md: 16,
    '2xl': is4k ? 65 : is2k ? 35 : 32,
  })
  console.log('type', type)
  console.log('type123', indicatorTypeData['Renewables'].description)
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      justifyContent={'space-between'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        p={{
          base: '0 3%',
          '2xl': is4k ? '3%' : '0 3%',
        }}
        color={'grey.800'}
      >
        <Box flexBasis={'52%'} alignSelf={'baseline'}>
          <Text
            marginBottom={5}
            fontSize={{
              base: 18,
              md: 20,
              lg: 11,
              xl: 15,
              '2xl': is4k ? '40px' : is2k ? '28px' : 20,
            }}
          >
            {cardImage[type].title}
          </Text>
          <Image
            src={cardImage[type].src}
            alt={'image'}
            fit={'contain'}
            height={{ '2xl': is4k ? '350px' : is2k ? 170 : 130 }}
          />
        </Box>
        <Box flexBasis={'45%'} alignSelf={'baseline'}>
          <NumberCardTitle
            align={'right'}
            textTransform={'uppercase'}
            content={
              ['PV', 'Battery'].includes(type)
                ? indicatorTypeData['Renewables'].description
                : indicatorTypeData[type].description
            }
          />
          <NumberCardTitle align={'right'} content={'Over the last week'} />
          <NumberCard
            fontSize={NumberCardTitleFont}
            number={measurement}
            bgColor={ColorCard({ measurement, target, upIsBetter })}
            type={type}
          />
          {/* <NumberCardTitle align={'right'} content={'Against a target of'} /> */}
          {/* <NumberCard
            fontSize={NumberCardTitleFont}
            number={target}
            bgColor={'neutral'}
            type={type}
          /> */}
        </Box>
      </Box>
      {!isRenewables(type) && (
        <Comparison
          p={'2%'}
          fontSize={comparisonFontSize}
          color={'grey.800'}
          text={comparison}
          centerAlign={true}
          isSmallCard={true}
        />
      )}
    </Box>
  )
}

export default SecondaryCard
