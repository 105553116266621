import { Box, Text } from '@chakra-ui/react'
import { indicatorTypeData } from '../../../utils/indicators/indicators'

type numberCardProps = {
  fontSize: number | undefined
  number: number | undefined
  bgColor: string
  type: string
  //   type: string may be in future
}

const NumberCard = ({
  fontSize = 32,
  number,
  bgColor,
  type,
}: numberCardProps) => {
  return (
    <Box
      display={'flex'}
      alignItems={'baseline'}
      color={'white'}
      bg={bgColor}
      p={'0 2%'}
      mb={4}
      lineHeight={1.2}
    >
      <Text
        fontSize={{
          base: fontSize / 1.3,
          md: fontSize,
          lg: fontSize / 2,
          '2xl': fontSize,
        }}
      >
        <b>{number?.toFixed(3)}</b>
      </Text>
      <Text
        fontSize={{
          base: fontSize / 1.7,
          md: fontSize,
          lg: fontSize / 2.4,
          '2xl': fontSize / 1.435,
        }}
      >
        <b>
          {['PV', 'Battery'].includes(type)
            ? indicatorTypeData['Renewables'].unit
            : indicatorTypeData[type].unit}
        </b>
      </Text>
    </Box>
  )
}

export default NumberCard
