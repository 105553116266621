import { Skeleton, Td, Tr, Image, Link, Text, Button } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import PfTrafficLightBlock from '../../elements/pfTrafficLightBlock'
import DefaultImage from '../../assets/Default-image.png'

type BuildingRowProps = {
  locale: string
  name: string
  slug: string
  type: string
  floorArea: number
  epcRating: string
  thumbnail: string
  useSimpleDash: boolean
  tlData: {
    type: string
    value: number
    target: number
    units: string
  }[]
  loading: boolean
}

const PfTableRow = ({
  locale,
  name,
  slug,
  type,
  floorArea,
  epcRating,
  thumbnail,
  useSimpleDash,
  tlData,
  loading,
}: BuildingRowProps) => {
  return (
    <Tr>
      <Td p={[0, 0]} position="relative">
        {useSimpleDash ? (
          <Link as={RouterLink} to={`${slug}/dashboard`}>
            <Image
              src={thumbnail || DefaultImage}
              alt="a picture of the builidng"
              boxSize="75px"
            />
          </Link>
        ) : (
          <Link as={RouterLink} to={`${slug}/dashboard`}>
            <Image
              src={thumbnail || DefaultImage}
              alt="a picture of the builidng"
              boxSize="75px"
            />
          </Link>
        )}
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          <Link as={RouterLink} to={`${slug}`}>
            {name}
          </Link>
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          {type.toLocaleUpperCase(locale)}
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          <Button variant={'outline'} colorScheme={'purple.500'}>
            <Link
              as={RouterLink}
              to={`${slug}`}
              textDecoration={'unset ! important'}
            >
              <Text color={'purple.500'}>Details</Text>
            </Link>
          </Button>
          <Button variant={'outline'} colorScheme={'purple.500'} ml={4}>
            <Link
              as={RouterLink}
              to={`${slug}/dashboard`}
              textDecoration={'unset ! important'}
            >
              <Text color={'purple.500'}>Dashboard</Text>
            </Link>
          </Button>
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>
          {floorArea.toLocaleString(locale, { maximumSignificantDigits: 5 })}
        </Skeleton>
      </Td>
      <Td>
        <Skeleton isLoaded={!loading}>{epcRating}</Skeleton>
      </Td>
      {tlData.slice(0, 3).map((tlDatum) => (
        <Td p={0.75} key={tlDatum.type}>
          {PfTrafficLightBlock({
            type: tlDatum.type,
            value: tlDatum.value,
            target: tlDatum.target,
            units: tlDatum.units,
            loading,
          })}
        </Td>
      ))}
    </Tr>
  )
}

export default PfTableRow
