import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
} from '@chakra-ui/react'
import { Channel, ChannelDataType } from '../../Pages/buildings/buildingDetails'
// import { formatDate } from '../../utils/dateHelpers/timeStampConversions'

export type SimpleTableProps = {
  channelListData: Channel[]
  channelGraphData: ChannelDataType
  filterChannels: (arg1: number) => void
  channelGraphDataLoading: boolean
}

export const SimpleTable = ({
  channelListData,
  channelGraphData,
  filterChannels,
  channelGraphDataLoading,
}: SimpleTableProps) => {
  return (
    <TableContainer maxHeight={'400px'} overflowY={'scroll'}>
      <Table
        variant="simple"
        size={'sm'}
        style={{
          borderCollapse: 'separate',
          borderSpacing: '5px',
          border: 'none',
        }}
      >
        <Thead>
          <Tr h={'56px'}>
            <Th bg={'#5f588d'} color={'white'}>
              Channel Name
            </Th>
            <Th bg={'#5f588d'} color={'white'}>
              Type
            </Th>
            <Th bg={'#5f588d'} color={'white'}>
              Unit
            </Th>
            <Th bg={'#5f588d'} color={'white'}>
              Earlier Measurement Date
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {channelListData &&
            channelListData.map((channelData: Channel, i: number) => (
              <Tr key={i} color={'#5f588d'}>
                <Td textTransform={'capitalize'} bg={'grey.300'}>
                  {channelData.name}
                </Td>
                <Td textTransform={'capitalize'} bg={'grey.300'}>
                  {channelData.measurementType}
                </Td>
                <Td bg={'grey.300'}>{channelData.unit.name}</Td>
                {/* <Td bg={'grey.300'}>{formatDate(channelData.data[0].time)}</Td> */}
                <Td w={0} border={'none'}>
                  <Button
                    onClick={() => filterChannels(channelData.id)}
                    key={i}
                    borderRadius={0}
                    border={'1px solid purple'}
                    variant={'outline'}
                    isDisabled={channelGraphDataLoading}
                  >
                    {channelGraphData && channelGraphData.id === channelData.id
                      ? 'Hide'
                      : 'View'}
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default SimpleTable
