import { gql, useQuery } from '@apollo/client'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
//import { m } from 'framer-motion'
import {
  //Resolver,
  useForm,
} from 'react-hook-form'
//import FloatingLabelInput from '../../elements/formInput/floatingLabel'
import Title from '../../elements/title'
import slugify from 'slugify'

// type BuildingCreateInput = {
//   name: string
//   projectRef: string
//   slug: string
//   epcRating: string
//   floorArea: number
//   type: number
//   staffAdmin: number
//   client: number
//   clientAdmin: number
//   occupier: number
//   occupierAdmin: number
//   hasFileServer: boolean
//   hasDataServer: boolean
//   useSimpleDash: boolean
// }

// const mCREATE_BUILDING = gql`
//   mutation newBuilding($data: BuildingCreateInput) {
//     createBuilding(data: $data) {
//       id
//       slug
//       name
//     }
//   }
// `

// Need some pre-populated info for the user to select
const qNEW_BUILDING_FORM = gql`
  query qNewBuildingForm($UsersInput: UsersInput!) {
    companies {
      id
      name
    }
    users(UsersInput: $UsersInput) {
      id
      firstName
      lastName
    }
    buildingTypes {
      id
      type
    }
  }
`

type QNewBuildingForm = {
  companies: {
    id: number
    name: string
  }[]
  users: {
    id: number
    firstName: string
    lastName: string
  }[]
  buildingTypes: {
    id: number
    type: string
  }[]
}

type FormValues = {
  name: string
  projectRef: string
  slug: string
  epcRating: string
  floorArea: number
  staffAdmin: number
  buildingType: number
  client: number
  clientAdmin: number
  occupier: number
  occupierAdmin: number
}

const NewBuilding = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>()
  const { data: queryData, loading: queryLoading } = useQuery<QNewBuildingForm>(
    qNEW_BUILDING_FORM,
    {
      variables: {
        UsersInput: {},
      },
    },
  )
  const [slug, setSlug] = useState<string>('')

  const urlSafe = (candidate: string): string => {
    return slugify(candidate, {
      replacement: '-',
      //remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
    })
  }

  function onSubmit(values: FormValues) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2))
        resolve()
      }, 3000)
    })
  }

  function onBuildingNameChange(e: ChangeEvent<HTMLInputElement>) {
    setSlug(urlSafe(e.target.value))
  }

  //console.log(`errors: `, errors)
  //console.log(`queryLoading: `, queryLoading)
  //console.log(`queryData`, queryData)

  return (
    <>
      <Title>New Building</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          templateAreas={{
            base: `"empty1 name projectRef empty2"
                   "empty1 slug epcRating empty2"
                   "empty1 floorArea details empty2"
                   "empty1 staff type empty2"
                   "empty1 client clientAdmin empty2"
                   "empty1 occupier occupierAdmin empty2"
                   "empty1 submit cancel empty2"`,
          }}
          gridTemplateColumns={{ base: '1fr 2fr 2fr 1fr' }}
          gridTemplateRows={'repeat(3, 1fr)'}
        >
          <GridItem area="empty1"></GridItem>
          <GridItem area="empty2"></GridItem>
          <GridItem area="name" px={4} py={2}>
            {/* <FloatingLabelInput
              type="text"
              id="name"
              label="Building Name"
              placeholder=""
              isRequired
              isInvalid={!!errors.name?.message}
              errorMessage={errors.name?.message}
              {...register('name', {
                required: 'requiredItem',
              })}
            /> */}
            <FormControl variant="floating" id="name">
              <Input
                type="text"
                placeholder=""
                {...register('name', {
                  required: 'This is required',
                  minLength: { value: 4, message: 'Min length 4' },
                  onChange: (e) => onBuildingNameChange(e),
                })}
              />
              <FormLabel>Building Name</FormLabel>
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem area="projectRef" px={4} py={2}>
            <FormControl variant="floating" id="projectRef">
              <Input type="text" {...register('projectRef')} />
              <FormLabel>Project Reference</FormLabel>
            </FormControl>
          </GridItem>
          <GridItem area="slug" px={4} py={2}>
            <FormControl variant="floating">
              <Input type="text" id="slug" value={slug} {...register('slug')} />
              <FormLabel>Building Slug</FormLabel>
            </FormControl>
          </GridItem>
          <GridItem area="epcRating" px={4} py={2}>
            <FormControl variant="floating">
              <Input type="text" id="epcRating" {...register('epcRating')} />
              <FormLabel>EPC Rating</FormLabel>
            </FormControl>
          </GridItem>
          <GridItem area="floorArea" px={4} py={2}>
            <FormControl variant="floating">
              <Input type="number" id="floorArea" {...register('floorArea')} />
              <FormLabel>Floor Area</FormLabel>
            </FormControl>
          </GridItem>
          <GridItem area="details" px={4} py={2}>
            details
          </GridItem>
          <GridItem area="staff" px={4} py={2}>
            {queryLoading ? (
              <p>loading</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="staffAdmin"
                  {...register('staffAdmin', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.users.map((user) => (
                    <option
                      key={`${user.id}`}
                      value={user.id}
                    >{`${user.firstName} ${user.lastName}`}</option>
                  ))}
                </Select>
                <FormLabel>Staff Admin</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="type" px={4} py={2}>
            {queryLoading ? (
              <p>Loading...</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="buildingType"
                  {...register('buildingType', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.buildingTypes.map((buildType) => (
                    <option key={`${buildType.id}`} value={buildType.id}>
                      {buildType.type}
                    </option>
                  ))}
                </Select>
                <FormLabel>Building Type</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="client" px={4} py={2}>
            {queryLoading ? (
              <p>Loading...</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="client"
                  {...register('client', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.companies.map((company) => (
                    <option key={`${company.id}`} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
                <FormLabel>Client Company</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="clientAdmin" px={4} py={2}>
            {queryLoading ? (
              <p>Loading...</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="clientAdmin"
                  {...register('clientAdmin', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.users.map((user) => (
                    <option key={`${user.id}`} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </option>
                  ))}
                </Select>
                <FormLabel>Client Admin</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="occupier" px={4} py={2}>
            {queryLoading ? (
              <p>Loading...</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="occupier"
                  defaultValue={1}
                  {...register('occupier', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.companies.map((company) => (
                    <option key={`${company.id}`} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
                <FormLabel>Occupying Company</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="occupierAdmin" px={4} py={2}>
            {queryLoading ? (
              <p>Loading...</p>
            ) : (
              <FormControl variant="floating">
                <Select
                  id="client"
                  {...register('occupierAdmin', {
                    required: 'This is required',
                  })}
                >
                  {queryData?.users.map((user) => (
                    <option key={`${user.id}`} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </option>
                  ))}
                </Select>
                <FormLabel>Occupier Admin</FormLabel>
              </FormControl>
            )}
          </GridItem>
          <GridItem area="submit" px={4} py={2}>
            <Button
              colorScheme={'purple'}
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
            <Button
              colorScheme={'gray'}
              variant={'outline'}
              type="reset"
              px={4}
              mx={4}
            >
              Clear
            </Button>
          </GridItem>
          <GridItem area="cancel"></GridItem>
        </Grid>
      </form>
    </>
  )
}

export default NewBuilding
