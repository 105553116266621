import { Box, Heading } from '@chakra-ui/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {
  XYChart,
  AnimatedAxis,
  AnimatedGrid,
  AnimatedBarGroup,
  AnimatedBarSeries,
} from '@visx/xychart'

export interface EnergyUseProps {
  loading: boolean
  data?: {
    duration: string
    use: {
      type: string
      label: string
      target: number
      value: number
    }[]
  }
}

const EnergyUse = ({ data, loading }: EnergyUseProps) => {
  if (loading || data === undefined) {
    return (
      <Box
        py={6}
        pl={6}
        pr={3}
        background={'gray.300'}
        textColor={'purple.600'}
        h="100%"
      >
        <Box background={'white'} p={4} h="100%">
          <Heading as="h3" size="md">
            Energy Use
          </Heading>
          <Box height="300px" width="100px">
            loading...
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      py={6}
      pl={6}
      pr={3}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box background="white" p={4} h="100%">
        <Heading as="h3" size="md">
          Energy Use
        </Heading>
        <Box height="300px" width="100%">
          <ParentSize>
            {({ width, height }) => (
              <XYChart
                height={height}
                width={width}
                xScale={{ type: 'band', paddingInner: 0.2 }}
                yScale={{ type: 'linear' }}
                margin={{ top: 40, right: 50, left: 70, bottom: 70 }}
              >
                <AnimatedGrid
                  rows={false}
                  columns={false}
                  animationTrajectory={'center'}
                />
                <AnimatedBarGroup>
                  <AnimatedBarSeries
                    dataKey="Energy Use"
                    data={data.use}
                    yAccessor={(d) => d.value / 1000}
                    xAccessor={(d) => d.label}
                    colorAccessor={(d) => {
                      const ratio = d.value / d.target
                      if (ratio > 1.1) {
                        return '#f2253e'
                      } else if (ratio > 0.9) {
                        return '#f9c138'
                      }
                      return '#91b706'
                    }}
                  />
                  <AnimatedBarSeries
                    dataKey="Target"
                    data={data.use}
                    yAccessor={(d) => d.target / 1000}
                    xAccessor={(d) => d.label}
                    colorAccessor={(_d) => '#372e70'}
                  />
                </AnimatedBarGroup>
                <AnimatedAxis
                  key={'use-axis'}
                  orientation={'bottom'}
                  label={'End Use'}
                  animationTrajectory="outside"
                  labelProps={{
                    fontFamily: 'bouldregular',
                    textAnchor: 'middle',
                    fontSize: 14,
                  }}
                  labelOffset={35}
                  tickLabelProps={(props) => {
                    return {
                      ...props,
                      fontFamily: 'bouldregular',
                      angle: 45,
                      textAnchor: 'start',
                    }
                  }}
                />
                <AnimatedAxis
                  key={'consumption-axis'}
                  orientation="left"
                  animationTrajectory="max"
                  label={'Consumption /kWh'}
                  labelProps={{
                    fontFamily: 'bouldregular',
                    textAnchor: 'middle',
                    fontSize: 14,
                  }}
                  labelOffset={30}
                  tickLabelProps={(props) => {
                    return {
                      ...props,
                      fontFamily: 'bouldregular',
                    }
                  }}
                />
              </XYChart>
            )}
          </ParentSize>
        </Box>
      </Box>
    </Box>
  )
}

export default EnergyUse
