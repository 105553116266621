import React from 'react'
import { Group } from '@visx/group'
import { LinePath } from '@visx/shape'
import { AxisLeft, AxisBottom, AxisScale } from '@visx/axis'

import { GridRows, GridColumns } from '@visx/grid'
import * as allCurves from '@visx/curve'
import { MarkerCircle } from '@visx/marker'

import {
  BuildingDetailsArrayProps,
  ChannelDataType,
} from '../../Pages/buildings/buildingDetails'
import { graphColors } from '../../utils/colorMaps/tightMap'

const axisColor = '#170B33'

// accessors
const getDate = (d: BuildingDetailsArrayProps) => new Date(d.time)
const getStockValue = (d: BuildingDetailsArrayProps) => d.value

export default function AreaChart({
  data,
  gradientColor,
  width,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
  isBrush,
  xMax,
}: {
  data: ChannelDataType
  gradientColor: string
  xScale: AxisScale<number>
  yScale: AxisScale<number>
  width: number
  yMax: number
  xMax: number
  margin: { top: number; right: number; bottom: number; left: number }
  hideBottomAxis?: boolean
  hideLeftAxis?: boolean
  isBrush?: boolean
  top?: number
  left?: number
  children?: React.ReactNode
}) {
  if (width < 10) return null
  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <MarkerCircle id="marker-circle" fill="#333" size={1} refX={1} />
      {!isBrush ? (
        <GridRows scale={yScale} width={xMax} height={yMax} stroke="#e0e0e0" />
      ) : (
        ''
      )}
      {!isBrush ? (
        <GridColumns
          scale={xScale}
          width={xMax}
          height={yMax}
          stroke="#e0e0e0"
        />
      ) : (
        ''
      )}
      <LinePath<BuildingDetailsArrayProps>
        curve={isBrush ? allCurves['curveLinear'] : allCurves['curveMonotoneX']}
        data={data?.data}
        x={(d) => xScale(getDate(d)) ?? 0}
        y={(d) => yScale(getStockValue(d)) ?? 0}
        stroke={graphColors[0]}
        strokeWidth={isBrush ? 1 : 3}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
      />
      {!hideBottomAxis && (
        <AxisBottom
          top={yMax}
          scale={xScale}
          numTicks={width > 520 ? 10 : 5}
          stroke={axisColor}
          tickStroke={axisColor}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          scale={yScale}
          numTicks={5}
          stroke={axisColor}
          tickStroke={axisColor}
        />
      )}
      {children}
    </Group>
  )
}
