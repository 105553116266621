import {
  Box,
  Image,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import Comparison from '../../comparison/comparison'
import { ColorCard } from '../../../utils/valueInference/colorCard'

import BuildingTotal from '../../../assets/SimpleDash/primary/001-TotalBuilding.svg'
import WaterTotal from '../../../assets/SimpleDash/primary/002-TotalWater.svg'
import CarbonTotal from '../../../assets/SimpleDash/primary/003-TotalCarbon.svg'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

type primaryCardProps = {
  measurement: number
  type: string
  comparison: string
  target: number
}

type cardObjectProps = {
  text: string
  src: string
  unit: JSX.Element | string
  [key: string]: JSX.Element | string
}
interface CardType {
  WholeBuilding: cardObjectProps
  WaterUsage: cardObjectProps
  CarbonDioxide: cardObjectProps
  [key: string]: cardObjectProps
}

const cardType: CardType = {
  WholeBuilding: { text: 'energy', src: BuildingTotal, unit: 'MWh' },
  WaterUsage: {
    text: 'water',
    src: WaterTotal,
    unit: (
      <>
        m<sup>3</sup>
      </>
    ),
  },
  CarbonDioxide: {
    text: 'carbon emissions',
    src: CarbonTotal,
    unit: (
      <>
        tCO<sub>2</sub>
      </>
    ),
  },
}

const PrimaryCard = ({
  measurement,
  type,
  comparison,
  target,
}: primaryCardProps) => {
  const bgColor = ColorCard({ measurement, target })
  const [is4k, is2k] = useMediaQuery(mediaQueries)
  const comparisonFontSize = useBreakpointValue({
    base: 25,
    md: 16,
    '2xl': is4k ? 45 : is2k ? 30 : 20,
  })
  return (
    <Box height={'100%'}>
      <Box
        bg={bgColor}
        position={'relative'}
        p={'5%'}
        h={{
          base: 175,
          md: 350,
          lg: 195,
          xl: 250,
          '2xl': is4k ? '800px' : is2k ? '435px' : 315,
        }}
      >
        <Box color={'white'} maxWidth={'75%'}>
          <Text
            noOfLines={1}
            textTransform={'uppercase'}
            fontSize={{
              base: 13,
              md: 25,
              lg: 15,
              '2xl': is4k ? '55px' : is2k ? '28px' : 22,
            }}
          >
            Total {cardType[type].text} Used
          </Text>
          <Box display={'flex'} alignItems={'baseline'} lineHeight={1}>
            <Text
              noOfLines={1}
              fontSize={{
                base: 40,
                md: 85,
                lg: 50,
                '2xl': is4k ? '210px' : is2k ? '100px' : '75px',
              }}
            >
              <b>{measurement.toFixed(2)}</b>
            </Text>
            <Text
              fontSize={{
                base: 28,
                md: 65,
                lg: 40,
                '2xl': is4k ? '150px' : is2k ? '65px' : 60,
              }}
            >
              <b>{cardType[type].unit}</b>
            </Text>
          </Box>
          <Text
            fontSize={{
              base: 13,
              md: 28,
              lg: 18,
              '2xl': is4k ? '75px' : is2k ? '30px' : 24,
            }}
            align={'center'}
          >
            Over the last week
          </Text>
        </Box>
        <Box
          position={'absolute'}
          bottom={{
            base: '-14px',
            md: '-22px',
            lg: '-14px',
            '2xl': is4k ? '-40px' : is2k ? '-26px' : '-25px',
          }}
          right={{ '2xl': is4k ? '2px' : is2k ? '-2px' : '-1px' }}
        >
          <Image
            src={cardType[type].src}
            alt={'Total-buidling.svg'}
            w={{
              '2xl': is4k ? '1265px' : is2k ? '840px' : 'auto',
            }}
          />
        </Box>
      </Box>
      <Comparison
        p={'7% 0 3%'}
        fontSize={comparisonFontSize}
        color={'grey.800'}
        text={comparison}
        centerAlign={true}
      />
    </Box>
  )
}

export default PrimaryCard
