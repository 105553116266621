import { useEffect } from 'react'
import { Box, Skeleton, Text } from '@chakra-ui/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import BrushChart from '../../elements/graphPlots/brushChart'
import SimpleTable from '../../elements/table/simpleTable'
import Legend from '../../elements/graphPlots/Legend'
import { graphColors } from '../../utils/colorMaps/tightMap'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

export type BuildingDetailsArrayProps = {
  time: string
  value: number
}

export type ChannelDataType =
  | {
      id: number
      name: string
      unit: {
        name: string
      }
      measurementType: string
      hide: boolean
      updatedAt: string
      createdAt: string
      data: BuildingDetailsArrayProps[]
    }
  | undefined

export type BuildingDetailsProps = {
  loading: boolean
  channelListData: Channel[] | undefined
  channelGraphData: ChannelDataType | undefined
  getChannelData: (arg1: GetChannelDataType) => {}
  channelGraphDataLoading: boolean
  channelListDataLoading: boolean
}

export const BuildingDetails = ({
  loading = true,
  channelListData,
  channelGraphData,
  getChannelData,
  channelGraphDataLoading,
  channelListDataLoading,
}: BuildingDetailsProps) => {
  const filterChannels = (id: number) => {
    getChannelData({
      variables: {
        where: {
          id,
        },
      },
    })
  }

  return (
    <Box>
      <Box p={'15px 10%'}>
        <Skeleton isLoaded={!channelListDataLoading}>
          <Text fontSize={40} color={'purple.500'}>
            {channelListData && channelListData[0]?.Building.name}
          </Text>
        </Skeleton>
      </Box>

      <Box bg={'grey.300'} p={'30px 10%'}>
        <Text fontSize={30} color={'purple.500'} mb={'15px'}>
          Detail Data
        </Text>
        {loading ? (
          <Skeleton minHeight={`calc(100vh - 620px)`} />
        ) : (
          <Box display={'flex'} bg={'white'} p={'15px'} alignItems={'center'}>
            <Box w={'100%'} height={`calc(100vh - 650px)`} flexGrow={1}>
              <ParentSize>
                {({ width, height }) => (
                  <BrushChart
                    width={width}
                    height={height}
                    channelGraphData={channelGraphData}
                  />
                )}
              </ParentSize>
            </Box>
            <Box width={'min-content'}>
              <Legend
                legendNames={[channelGraphData!.name]}
                graphColors={graphColors}
                size={10}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box p={'30px 10%'}>
        <Text fontSize={30} color={'purple.500'} mb={'15px'}>
          Select Channel
        </Text>
        <SimpleTable
          channelListData={channelListData!}
          channelGraphData={channelGraphData}
          filterChannels={filterChannels}
          channelGraphDataLoading={channelGraphDataLoading}
        />
      </Box>
    </Box>
  )
}

interface BuildingWhereUniqueInput {
  where: {
    id?: number
    projectRef?: string
    slug?: string
  }
}

type BuildingGQL = {
  id: number
}

type GetBuildingData = {
  building: BuildingGQL
}

const BUILDING_QUERY = gql`
  query getBuilding($where: BuildingWhereUniqueInput!) {
    building(where: $where) {
      id
    }
  }
`
interface ChannelsFilter {
  Building: number
}

interface ChannelSearchInput {
  filter: ChannelsFilter
  take: number
}

export type Channel = {
  id: number
  name: string
  Building: {
    name: string
  }
  unit: {
    name: string
  }
  measurementType: string
}

interface ChannelList {
  channels: Channel[]
}

const CHANNEL_QUERY = gql`
  query channelData($filter: ChannelsFilter, $take: Int) {
    channels(filter: $filter, take: $take) {
      id
      name
      measurementType
      Building {
        name
        slug
      }
      createdAt
      period
      reference
      # data {
      #   time
      #   value
      # }
      unit {
        name
      }
    }
  }
`

interface ChannelWhereUniqueInput {
  where: {
    buildingId?: number
    id?: number
    uploadLabel?: string
  }
}

type GetChannelGraphData = {
  channel: ChannelDataType
}

type GetChannelDataType = {
  variables: {
    where: {
      id: number
    }
  }
}

const CHANNEL_DATA_QUERY = gql`
  query channelGraphData($where: ChannelWhereUniqueInput!) {
    channel(where: $where) {
      id
      name
      data {
        time
        value
      }
      measurementType
      createdAt
      period
      reference
      unit {
        category {
          category
          createdAt
          id
          updatedAt
        }
        createdAt
        id
        name
        siUnit
        summable
        updatedAt
      }
      updatedAt
      uploadLabel
    }
  }
`
export const WrappedBuildingDetails = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown_building'
  const { data: buildingData } = useQuery<
    GetBuildingData,
    BuildingWhereUniqueInput
  >(BUILDING_QUERY, { variables: { where: { slug: buildingSlug } } })

  const [
    getChannelList,
    { data: channelListData, loading: channelListDataLoading },
  ] = useLazyQuery<ChannelList, ChannelSearchInput>(CHANNEL_QUERY)

  let [
    getChannelData,
    { data: rawChannelGraphData, loading: channelGraphDataLoading },
  ] = useLazyQuery<GetChannelGraphData, ChannelWhereUniqueInput>(
    CHANNEL_DATA_QUERY,
  )

  let channelGraphData = rawChannelGraphData
  if (
    rawChannelGraphData?.channel!.measurementType === 'CUMULATIVE' &&
    rawChannelGraphData?.channel?.data
  ) {
    const newData = rawChannelGraphData.channel.data.map(
      (datum: BuildingDetailsArrayProps, i: number) => {
        const newValue =
          i - 1
            ? (rawChannelGraphData?.channel?.data[i]?.value || 0) -
              (rawChannelGraphData?.channel?.data[i - 1]?.value || 0)
            : 0
        return { ...datum, value: newValue }
      },
    )
    channelGraphData = {
      ...rawChannelGraphData,
      channel: {
        ...rawChannelGraphData.channel,
        data: newData,
      },
    }
    channelGraphData.channel!.data.sort(
      (a: BuildingDetailsArrayProps, b: BuildingDetailsArrayProps) =>
        new Date(a.time).getTime() - new Date(b.time).getTime(),
    )
  }

  useEffect(() => {
    if (buildingData?.building) {
      getChannelList({
        variables: {
          filter: {
            Building: buildingData?.building.id,
          },
          take: 100,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData])

  useEffect(() => {
    if (channelListData) {
      getChannelData({
        variables: {
          where: {
            id: channelListData?.channels[0].id,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelListData])

  return (
    <>
      <BuildingDetails
        loading={!(channelListData?.channels && channelGraphData?.channel)}
        channelListData={channelListData?.channels}
        channelGraphData={channelGraphData?.channel}
        getChannelData={getChannelData}
        channelGraphDataLoading={channelGraphDataLoading}
        channelListDataLoading={channelListDataLoading}
      />
    </>
  )
}
