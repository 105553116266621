import { gql, useQuery } from '@apollo/client'
import { ComponentWithAs, IconProps } from '@chakra-ui/react'
import PfHeadline from '../../components/pfHeadline/pfHeadline'
import PfTable, { BuildingProps } from '../../components/pfTable/pfTable'
import Hero from '../../elements/hero'
import CO2 from '../../elements/icons/co2'
import Renewables from '../../elements/icons/renewables'
import WaterUsage from '../../elements/icons/waterUsage'
import WholeBuilidng from '../../elements/icons/wholeBuilding'
import Title from '../../elements/title'
import { useAuth } from '../../hooks/use-auth'

/*
const headlineItems = [
  {
    Icon: WholeBuilidng,
    label: 'buildingEnergy',
    value: 123.3,
    target: 140,
    units: 'MWh',
    description: 'totalEnergyUsed',
    upIsBetter: false,
  },
  {
    Icon: CO2,
    label: 'carbonDioxide',
    value: 123.3,
    target: 70,
    units: 'tCO2',
    description: 'totalEmissions',
    upIsBetter: false,
  },

  {
    Icon: WaterUsage,
    label: 'water',
    value: 123.3,
    target: 200,
    units: 'm3',
    description: 'totalWaterUsed',
    upIsBetter: false,
  },

  {
    Icon: Renewables,
    label: 'renewables',
    value: 123.3,
    target: 100,
    units: 'MWh',
    description: 'totalGenerated',
    upIsBetter: true,
  },
]
*/
export interface HeadlineItems {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
}

export interface BuildingsProps {
  loading: boolean
  company: {
    name: string
    portfolioImage: string
    slug: string
  }
  headlineItems: HeadlineItems[] | undefined
  buildings: BuildingProps[] | undefined
}

export const Buildings = ({
  loading,
  company,
  headlineItems,
  buildings,
}: BuildingsProps) => {
  return (
    <>
      <Title loading={loading}>{company.name}</Title>
      <Hero
        imageUrl={company.portfolioImage}
        altText={`${company.name} portfolio picture`}
      />
      <PfHeadline items={headlineItems ?? []} loading={loading} />
      <PfTable buildings={buildings ?? []} loading={loading} />
    </>
  )
}

interface Portfolio {
  type: string
  measurement: number
  target: number
}

interface BuildingsGQL {
  projectRef: string
  name: string
  slug: string
  type: {
    type: string
  }
  picture: string
  floorArea: number
  epcRating: string
  useSimpleDash: boolean
  portfolio: Portfolio[]
}

interface UserGQL {
  company: {
    name: string
    portfolioImage: string
    slug: string
  }
}

interface GetAdminData {
  user: UserGQL
  buildings: BuildingsGQL[]
}

interface UserWhereUniqueInputGQL {
  id?: number
  email?: string
  slug?: string
}

interface BuildingsInputGQL {
  name?: string
  staffAdmin?: number
}

interface BuildingsQueryInput {
  userWhere: UserWhereUniqueInputGQL
  buildingWhere: BuildingsInputGQL
}

const BUILDINGS_QUERY = gql`
  query getAdminData(
    $userWhere: UserWhereUniqueInput!
    $buildingWhere: BuildingsInput!
  ) {
    user(where: $userWhere) {
      company {
        name
        portfolioImage
        slug
      }
    }
    buildings(BuildingsInput: $buildingWhere) {
      projectRef
      name
      slug
      type {
        type
      }
      picture
      floorArea
      epcRating
      useSimpleDash
      portfolio {
        type
        measurement
        target
      }
    }
  }
`

const WrappedBuildings = () => {
  const auth = useAuth()
  const {
    data: queryData,
    loading,
    error,
  } = useQuery<GetAdminData, BuildingsQueryInput>(BUILDINGS_QUERY, {
    variables: {
      userWhere: {
        id: +auth.user.id,
      },
      buildingWhere: {},
    },
  })

  error && console.log(error)

  const buildings: BuildingProps[] | undefined = queryData?.buildings.map(
    (building) => {
      return {
        name: building.name,
        slug: building.slug,
        thumbnail: building.picture ?? '',
        type: building.type.type,
        epcRating: building.epcRating ?? 'Unknown',
        floorArea: building.floorArea ?? 0,
        useSimpleDash: building.useSimpleDash ?? false,
        tlData: building.portfolio
          .filter(
            (pfItem) =>
              pfItem.type === 'WholeBuilding' ||
              pfItem.type === 'CarbonDioxide' ||
              pfItem.type === 'Renewables',
          )
          .sort((a, b) => {
            const valA =
              a.type === 'WholeBuilding'
                ? 1
                : a.type === 'CarbonDioxide'
                ? 2
                : 3
            const valB =
              b.type === 'WholeBuilding'
                ? 1
                : b.type === 'CarbonDioxide'
                ? 2
                : 3

            return valA - valB
          })
          .map((pfItem) => {
            return {
              type:
                pfItem.type === 'WholeBuilding'
                  ? 'energy'
                  : pfItem.type === 'CarbonDioxide'
                  ? 'carbon'
                  : 'renewables',
              value: pfItem.measurement * 1000,
              target: pfItem.target * 1000,
              units: pfItem.type === 'CarbonDioxide' ? 'kg' : 'Wh',
            }
          }),
      }
    },
  )

  const headlineSums = queryData?.buildings?.reduce(
    (previousValue, currentValue) => {
      return {
        ...previousValue,
        floorArea: previousValue.floorArea + currentValue.floorArea,
        portfolio: previousValue.portfolio.map((previousPortfolio) => {
          const currentPortfolio = currentValue.portfolio.find(
            (pf) => pf.type === previousPortfolio.type,
          )
          return {
            type: previousPortfolio.type,
            measurement:
              previousPortfolio.measurement +
              (currentPortfolio ? currentPortfolio.measurement : 0),
            target:
              previousPortfolio.target +
              (currentPortfolio ? currentPortfolio.target : 0),
          }
        }),
      }
    },
  )

  const headlineItemsSums: HeadlineItems[] | undefined =
    headlineSums?.portfolio.map((pfItem) => {
      const specs: {
        Icon: ComponentWithAs<'svg', IconProps>
        label: string
        units: string
        description: string
        upIsBetter: boolean
      } =
        pfItem.type === 'WholeBuilding'
          ? {
              Icon: WholeBuilidng,
              label: 'buildingEnergy',
              units: 'kWh',
              description: 'totalEnergyUsed',
              upIsBetter: false,
            }
          : pfItem.type === 'CarbonDioxide'
          ? {
              Icon: CO2,
              label: 'carbonDioxide',
              units: 'tCO<sub>2</sub>',
              description: 'totalEmissions',
              upIsBetter: false,
            }
          : pfItem.type === 'WaterUsage'
          ? {
              Icon: WaterUsage,
              label: 'water',
              units: 'm<sup>3<sup>',
              description: 'totalWaterUsed',
              upIsBetter: false,
            }
          : pfItem.type === 'Renewables'
          ? {
              Icon: Renewables,
              label: 'renewables',
              units: 'kWh',
              description: 'totalGenerated',
              upIsBetter: true,
            }
          : {
              Icon: WholeBuilidng,
              label: 'Unknown',
              units: '',
              description: 'unknown total',
              upIsBetter: false,
            }

      return {
        ...specs,
        value: pfItem.measurement,
        target: pfItem.target,
      }
    })

  //buildings && buildings.length > 0 && console.log(buildings[0]?.tlData)

  // getParams here
  return (
    <Buildings
      loading={loading}
      company={
        queryData?.user.company || { name: '', portfolioImage: '', slug: '' }
      }
      headlineItems={headlineItemsSums}
      buildings={buildings}
    />
  )
}

export default WrappedBuildings
