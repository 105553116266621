import { useParams } from 'react-router-dom'
import {
  Box,
  //Button,
  //Center,
  Heading,
  LinkBox,
  LinkOverlay,
  //Radio,
  //RadioGroup,
  //Stack,
  Text,
  //useToast,
} from '@chakra-ui/react'
//import { useState } from 'react'

const ReportRequest = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown builidng'
  // const [duration, setDuration] = useState<string>('2')
  // const toast = useToast()

  return (
    <LinkBox>
      <Box
        py={6}
        pl={6}
        pr={3}
        background={'gray.300'}
        textColor={'purple.600'}
      >
        <Box background={'white'} p={4} height={'100%'}>
          <Heading as="h3" size="md">
            <LinkOverlay href={`/buildings/${buildingSlug}/details`}>
              Generate Report
            </LinkOverlay>
          </Heading>
          <Text py={6}>We will be making this available soon</Text>
        </Box>
      </Box>
    </LinkBox>
  )

  // return (
  //   <Box py={6} pl={6} pr={3} background={'gray.300'} textColor={'purple.600'}>
  //     <Box background={'white'} p={4}>
  //       <Heading as="h3" size="md">
  //         Generate Report
  //       </Heading>
  //       <RadioGroup py={4} onChange={setDuration} value={duration}>
  //         <Stack direction={'row'} spacing={4}>
  //           <Radio value="1">year</Radio>
  //           <Radio value="2">month</Radio>
  //           <Radio value="3">week</Radio>
  //           <Radio value="4">day</Radio>
  //         </Stack>
  //       </RadioGroup>
  //       <Center>
  //         <Button
  //           onClick={() => {
  //             toast({
  //               title: 'Report Requested',
  //               description:
  //                 'The requested report will be sent to the project lead engineer via email',
  //               status: 'success',
  //               duration: 9000,
  //               isClosable: true,
  //             })
  //           }}
  //           colorScheme={'purple'}
  //         >
  //           Request
  //         </Button>
  //       </Center>
  //     </Box>
  //   </Box>
  // )
}

export default ReportRequest
