/* eslint-disable @typescript-eslint/no-use-before-define */
import { useRef, useState, useMemo } from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { Brush } from '@visx/brush'
import { Bounds } from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { PatternLines } from '@visx/pattern'
import { Group } from '@visx/group'
import { max, extent } from 'd3-array'
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle'
import AreaChart from './areaChart'
import {
  BuildingDetailsArrayProps,
  ChannelDataType,
} from '../../Pages/buildings/buildingDetails'
import { Box } from '@chakra-ui/react'

// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 }
const chartSeparation = 30
const PATTERN_ID = 'brush_pattern'
const GRADIENT_ID = 'brush_gradient'
export const accentColor = '#000'
export const background = '#3C1D87'
export const background2 = '#3C1D87'
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'white',
}
// accessors
const getDate = (d: BuildingDetailsArrayProps) => new Date(d.time)
const getValue = (d: BuildingDetailsArrayProps) => Math.abs(d.value)

export type BrushProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  compact?: boolean
  channelGraphData: ChannelDataType
}

function BrushChart({
  compact = false,
  width,
  height,
  margin = {
    top: 50,
    left: 50,
    bottom: 20,
    right: 20,
  },
  channelGraphData,
}: BrushProps) {
  const brushRef = useRef<BaseBrush | null>(null)
  const details = channelGraphData
  const [filteredValues, setFilteredValues] = useState<ChannelDataType>(details)

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return
    const { x0, x1, y0, y1 } = domain
    let channelGraphCopy: ChannelDataType = channelGraphData
    const filteredData = channelGraphCopy?.data.filter(
      (s: BuildingDetailsArrayProps) => {
        const x = getDate(s).getTime()
        const y = getValue(s)
        return x > x0 && x < x1 && y > y0 && y < y1
      },
    )
    setFilteredValues({
      ...channelGraphCopy!,
      data: [...filteredData!],
    })
  }

  const innerHeight = height - margin.top - margin.bottom
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 10
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0)
  const yMax = Math.max(topChartHeight, 0)
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0,
  )

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: extent(filteredValues!.data, getDate) as [Date, Date],
      }),
    [xMax, filteredValues],
  )
  const valueScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [
          -max(filteredValues!.data, getValue)!,
          max(filteredValues!.data, getValue)!,
        ],
        nice: true,
      }),
    [yMax, filteredValues],
  )
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(details!.data, getDate) as [Date, Date],
      }),
    [xBrushMax, details],
  )
  const brushValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [-max(details!.data, getValue)!, max(details!.data, getValue)!],
        nice: true,
      }),
    [yBrushMax, details],
  )

  // const initialBrushPosition = useMemo(
  //   () => ({
  //     start: { x: brushDateScale(getDate(details[50])) },
  //     end: { x: brushDateScale(getDate(details[100])) },
  //   }),
  //   [brushDateScale, details],
  // )

  // event handlers
  // const handleClearClick = () => {
  //   if (brushRef?.current) {
  //     // setFilteredValues(details)
  //     brushRef.current.reset()
  //   }
  // }

  // const handleResetClick = () => {
  //   if (brushRef?.current) {
  //     const updater: UpdateBrush = (prevBrush) => {
  //       const newExtent = brushRef.current!.getExtent(
  //         initialBrushPosition.start,
  //         initialBrushPosition.end,
  //       )

  //       const newState: BaseBrushState = {
  //         ...prevBrush,
  //         start: { y: newExtent.y0, x: newExtent.x0 },
  //         end: { y: newExtent.y1, x: newExtent.x1 },
  //         extent: newExtent,
  //       }

  //       return newState
  //     }
  //     brushRef.current.updateBrush(updater)
  //   }
  // }

  return (
    <Box display={'flex'}>
      <Box>
        <div>
          <svg width={width} height={height}>
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={`url(#${GRADIENT_ID})`}
              rx={14}
            />
            <AreaChart
              hideLeftAxis={false}
              hideBottomAxis={compact}
              data={filteredValues}
              width={width}
              margin={{ ...margin, bottom: topChartBottomMargin }}
              yMax={yMax}
              xMax={xMax}
              xScale={dateScale}
              yScale={valueScale}
              isBrush={false}
              gradientColor={background2}
            />
            <AreaChart
              hideLeftAxis
              hideBottomAxis={false}
              data={details}
              isBrush={true}
              width={width}
              yMax={yBrushMax}
              xMax={xMax}
              xScale={brushDateScale}
              yScale={brushValueScale}
              margin={brushMargin}
              top={topChartHeight + topChartBottomMargin + margin.top}
              gradientColor={background2}
            >
              <PatternLines
                id={PATTERN_ID}
                height={5}
                width={5}
                stroke={accentColor}
                strokeWidth={2}
                orientation={['diagonal']}
              />
              <Brush
                xScale={brushDateScale}
                yScale={brushValueScale}
                width={xBrushMax}
                height={yBrushMax}
                margin={brushMargin}
                handleSize={8}
                innerRef={brushRef}
                resizeTriggerAreas={['left', 'right']}
                brushDirection="horizontal"
                // initialBrushPosition={initialBrushPosition}
                onChange={onBrushChange}
                // onClick={() => setFilteredValues(details)}
                selectedBoxStyle={selectedBrushStyle}
                useWindowMoveEvents
                renderBrushHandle={(props) => <BrushHandle {...props} />}
              />
            </AreaChart>
          </svg>
          {/* <Button m={'30px 0'} onClick={handleClearClick}>
        Clear
      </Button> */}
          {/* &nbsp; */}
          {/* <button onClick={handleResetClick}>Reset</button> */}
        </div>
      </Box>
    </Box>
  )
}

// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8
  const pathHeight = 20
  if (!isBrushActive) {
    return null
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  )
}

export default BrushChart
