import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

import Marquee from '../../components/marquee/marquee'
import PrimaryCard from '../../elements/building/simpleDashboard/primaryCard'
import SecondaryCard from '../../elements/building/simpleDashboard/secondaryCard'
import BalanceMeter from '../../elements/balanceMeter/balanceMeter'
import { BuildingDashboardGQL } from './dashboardWrapper'

import Logo from '../../assets/Opti-Twin-logo.png'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'

export const SimpleDash = ({
  dashboardSlug: dashboardData,
}: BuildingDashboardGQL) => {
  const [is4k, is2k] = useMediaQuery(mediaQueries)
  const cardData = dashboardData?.data.find(
    (datum) => datum.timescale === 'WEEK',
  )

  return (
    <Grid
      templateAreas={{
        base: ` "title"
                "main1"
                "main2"
                "main3"
                "secon"
                "renew"
                "marqu"`,
        md: ` "title title"
              "main1 main1"
              "main2 main2"
              "main3 main3"
              "secon secon"
              "renew renew"
              "marqu marqu"`,
        lg: ` "title title title title title title title title title title title title title title title"
              "main1 main1 main1 main1 main1 main2 main2 main2 main2 main2 main3 main3 main3 main3 main3"
              "secon secon secon secon secon secon secon secon secon renew renew renew renew renew renew"
              "marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu marqu"`,
      }}
      gridTemplateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        lg: 'repeat(15, 1fr)',
      }}
      gridAutoRows={{
        base: 'min-content',
        '2xl': is4k
          ? ' 200px min-content 1fr 200px'
          : is2k
          ? ' 100px min-content 1fr 100px'
          : 'min-content',
      }}
      w={'100%'}
      // minH={'100vh'}
      h={'100vh'}
      p={{ base: 2, md: 3, '2xl': 4 }}
      gap={2}
      background={'grey.100'}
    >
      <GridItem
        area="title"
        height={'100%'}
        overflow={'hidden'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Image
          src={Logo}
          alt={'Logo.png'}
          width={{
            base: 70,
            md: 200,
            '2xl': is4k ? '450px' : is2k ? '230px' : 200,
          }}
        />
        <Text
          fontSize={{
            base: 20,
            md: 40,
            lg: 50,
            '2xl': is4k ? '100px' : is2k ? '60px' : 50,
          }}
          color={'grey.800'}
        >
          {dashboardData?.name}
        </Text>
        <Image
          src={dashboardData?.occupierLogo}
          alt={'occupier-logo.svg'}
          width={{
            base: '80px',
            md: 180,
            xl: 250,
            '2xl': is4k ? '500px' : is2k ? '300px' : 250,
          }}
        />
      </GridItem>
      {cardData?.primary.map((datum: any, index: number) => (
        <GridItem
          area={`main${index + 1}`}
          position={'relative'}
          overflow={'hidden'}
          key={index}
        >
          <PrimaryCard
            measurement={datum.measurement}
            comparison={datum.comparison}
            type={datum.type}
            target={datum.target}
          />
        </GridItem>
      ))}
      <GridItem area="secon" overflow={'hidden'}>
        <SimpleGrid columns={{ lg: 3, md: 2, base: 1 }} gap={2} height={'100%'}>
          {cardData?.secondary.map((datum: any, i: number) => (
            <Box bg={'grey.300'} key={i}>
              <SecondaryCard
                type={datum.type}
                target={datum.target}
                measurement={datum.measurement}
                comparison={datum.comparison}
              />
            </Box>
          ))}
        </SimpleGrid>
      </GridItem>
      <GridItem
        area="renew"
        background={'grey.300'}
        padding={{ md: '1%', base: 0 }}
      >
        <SimpleGrid gap={4} columns={{ md: 2, base: 1 }} height={'100%'}>
          <Box>
            <SecondaryCard
              type={'PV'}
              target={cardData?.renewables.target}
              measurement={cardData?.renewables.measurement}
            />
          </Box>
          <Box bg={'grey.900'} p={'5%'} color={'white'}>
            <Center
              fontSize={{
                base: 18,
                md: 22,
                lg: 13,
                xl: 18,
                '2xl': is4k ? '50px' : is2k ? '30px' : 20,
              }}
            >
              Main Vs PV Balance
            </Center>
            <Center
              height={{
                base: 100,
                '2xl': is4k ? '300px' : is2k ? '150px' : 100,
              }}
            >
              <BalanceMeter
                consumed={cardData?.currentConsumption}
                generated={cardData?.currentGeneration}
              />
            </Center>
            <Center
              mt={30}
              fontSize={{
                base: 14,
                md: 16,
                lg: 10,
                xl: 13,
                '2xl': is4k ? '34px' : is2k ? '24px' : 16,
              }}
            >
              During daylight/generating hours
            </Center>
          </Box>
          <Box>
            {/* <SecondaryCard
              type={'Battery'}
              target={cardData?.renewables.target}
              measurement={cardData?.renewables.measurement}
            /> */}
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
              height={'100%'}
              p={{
                base: '0 3%',
                '2xl': is4k ? '3%' : '0 3%',
              }}
              color={'grey.800'}
            >
              <Center
                fontSize={{
                  base: 18,
                  md: 22,
                  lg: 13,
                  xl: 18,
                  '2xl': is4k ? '50px' : is2k ? '30px' : 20,
                }}
                textAlign={'center'}
              >
                When the photovoltaic array is enabled we'll update this section
                with live generation data.
              </Center>
            </Box>
          </Box>
          <Box bg={'grey.500'} p={'5%'} color={'white'}>
            <Center
              fontSize={{
                base: 18,
                md: 22,
                lg: 13,
                xl: 18,
                '2xl': is4k ? '50px' : is2k ? '30px' : 20,
              }}
            >
              Lifetime Carbon Savings
            </Center>
            <Text
              align={'center'}
              fontSize={{
                base: 12,
                md: 14,
                lg: 8,
                xl: 12,
                '2xl': is4k ? '34px' : is2k ? '20px' : 12,
              }}
            >
              By reducing consumption and generating on the building we have
              saved
            </Text>
            <Box
              display={'flex'}
              alignItems={'baseline'}
              justifyContent={'center'}
            >
              <Text
                fontSize={{
                  base: 50,
                  md: 60,
                  lg: 35,
                  xl: 50,
                  '2xl': is4k ? '100px' : is2k ? '50px' : 50,
                }}
              >
                {cardData?.lifetimeCarbonSavings}
              </Text>
              <Text
                fontSize={{
                  base: 38,
                  md: 42,
                  lg: 25,
                  xl: 36,
                  '2xl': is4k ? '60px' : is2k ? '30px' : 36,
                }}
              >
                <b>
                  {cardData?.lifetimeCarbonSavingsUnits.slice(0, -1)}
                  <sub>{cardData?.lifetimeCarbonSavingsUnits.slice(-1)}</sub>
                </b>
              </Text>
            </Box>
            <Center
              fontSize={{
                base: 14,
                md: 16,
                lg: 10,
                xl: 13,
                '2xl': is4k ? '34px' : is2k ? '30px' : 14,
              }}
            >
              Over the lifetime of this building
            </Center>
          </Box>
        </SimpleGrid>
      </GridItem>
      <GridItem
        area="marqu"
        style={{ position: 'relative' }}
        bg={'grey.300'}
        p={'12px'}
        overflow={'hidden'}
      >
        <Marquee
          textSpeed={0.5}
          phrase={[
            "We're monitoring your building to improve sustainable operations",
          ]}
        />
      </GridItem>
    </Grid>
  )
}

const SimpleDashWrapper = ({ dashboardSlug }: BuildingDashboardGQL) => {
  return <SimpleDash dashboardSlug={dashboardSlug} />
}

export default SimpleDashWrapper
