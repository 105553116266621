import { Box } from '@chakra-ui/react'
import Info from '../icons/info'

type comparisonProps = {
  fontSize: number | undefined
  centerAlign?: boolean
  p: string
  text: string | undefined
  isSmallCard?: boolean
  color: string
}

const Comparison = ({
  fontSize = 15,
  centerAlign,
  color,
  p,
  text,
  isSmallCard = false,
}: comparisonProps) => {
  return (
    <Box
      display={'flex'}
      p={p}
      color={color}
      fontSize={{
        base: fontSize / (isSmallCard ? 1.3 : 2),
        md: fontSize,
        lg: fontSize / 2,
        xl: fontSize / 1.8,
        '2xl': fontSize,
      }}
      alignItems={'center'}
      justifyContent={centerAlign ? 'center' : 'unset'}
    >
      <Box mr={3}>
        <Info
          fontSize={{
            base: fontSize / (isSmallCard ? 1.3 : 2),
            md: fontSize,
            lg: fontSize / 2,
            xl: fontSize / 1.5,
            '2xl': fontSize,
          }}
        />
      </Box>{' '}
      {text}
    </Box>
  )
}

export default Comparison
